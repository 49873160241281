import { formatDate } from '../../../../../common/utils/DateFunctions';

import HideIcon from '../../../../../common/components/icons/HideIcon';
import ShowIcon from '../../../../../common/components/icons/ShowIcon';

import global from '../../../../../common/components/GlobalStyle.module.css';
import style from './LargeGrid.module.css';

const RuleNotes = ({ ruleNotes }) => {
  return (
    <>
      <div className='row'>
        <div className='col-xs-12'>
          <p><strong><i>Additional clarification of requirement:</i></strong></p>
        </div>
      </div>
      <ul className={style.BulletList}>
        {ruleNotes.map((note, i) => <ul key={i} className={style.Bullet}>{note.ruleNote}</ul>)}
      </ul>
    </>
  );
};

const SmallGridRow = ({ certificationRule, expandedId, onExpandClicked }) => {
  return (
    <>
      <div className={global.SmallTableRow}>
        <div className={global.SmallTableRowHead}>
          <span className='hidden-xs'>{certificationRule.requirementType}</span>&nbsp;
          {(certificationRule.reviewerLastName
            || (Array.isArray(certificationRule.ruleNotes) && certificationRule.ruleNotes.length > 0))
            && (
              <button
                className={global.IconButton}
                type="button"
                onClick={(e) => onExpandClicked(e, certificationRule.certificationRuleId)}>
                {expandedId === certificationRule.certificationRuleId ? <HideIcon /> : <ShowIcon />}
              </button>)}
        </div>
        <div className={global.SmallTableRowInfo}>
          <div className='row'>
            <div className={['col-xs-6 col-sm-4 visible-xs', global.SmallTableRowLabels].join(' ')}>
              Requirement Type
            </div>
            <div className={['col-xs-6 col-sm-8  visible-xs', global.SmallTableRowData].join(' ')}>
              {certificationRule.requirementType || <span>&nbsp;</span>}
            </div>
            <div className={['col-xs-6 col-sm-4', global.SmallTableRowLabels].join(' ')}>
              Description
            </div>
            <div className={['col-xs-6 col-sm-8', global.SmallTableRowData].join(' ')}>
              {certificationRule.ruleDescription || <span>&nbsp;</span>}
            </div>
            {/* <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>
              Rule Value
            </div>
            <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>
              {certificationRule.ruleValue ? certificationRule.ruleValue : 'Not Yet Scored'}
            </div>
            <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>
              Completion Date
            </div>
            <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>
              {certificationRule.completionDate && certificationRule.completionDate !== ''
                ? formatDate(certificationRule.completionDate)
                : 'N/A'}
            </div>
            <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>
              Status
            </div>
            <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>
              {certificationRule.ruleStatus}
            </div> */}
          </div>
        </div>
      </div>
      {expandedId === certificationRule.certificationRuleId && <RuleNotes ruleNotes={certificationRule.ruleNotes} />}
    </>
  );
};

const GridSmall = ({ gridData, expandedId, onExpandClicked }) => {
  return (
    <div className={[global.SmallTable, 'hidden-md hidden-lg'].join(' ')}>
      {Array.isArray(gridData) && gridData.length > 0
        && gridData.map((certificationRule, i) => (
          <SmallGridRow
            key={i}
            certificationRule={certificationRule}
            expandedId={expandedId}
            onExpandClicked={onExpandClicked} />)
        )}
      {(!Array.isArray(gridData) || !gridData?.length) && (<span>No data for grid</span>)}
    </div>
  );
};

export default GridSmall;