
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from '../../../../../common/wrappers/ReactRouterDom';

import validate from './OtsMeetOfficialsOfficialDetailValidation';

import useOtsMeetData from '../../../../state/officialsTrackingSystemMeet/UseOtsMeetData';
import useOtsMeetSessionsData from '../../../../state/officialsTrackingSystemMeetSessions/UseOtsMeetSessionsData';
import useMeetOfficialSessionData from '../../../../state/meetOfficialSession/UseMeetOfficialSessionData';
import useMeetOfficialRoleTypeData from '../../../../state/meetOfficialRoleType/UseMeetOfficialRoleTypeData';

import useNavRoutes from '../../../../../common/state/security/UseNavRoutes';

import Constants from '../../../../../common/utils/Constants';
import useForm from '../../../../../common/utils/UseForm';
import { formatDate } from '../../../../../common/utils/DateFunctions';

const INITIAL_VIEW_STATE = {
  tryRedirect: false,
};

const INITIAL_FORM_STATE = {
  isEdit: false,
  meetOfficialPersonId: '',
  firstName: '',
  preferredName: '',
  middleName: '',
  lastName: '',
  meetOfficialMemberId: '',
  selectedValidMemberId: '',
  meetSessionIds: [],
  meetOfficialRoleTypeId: Constants.DEFAULT_ID,
  officialRoleTypeName: '',
  isApprentice: false
};

const useOtsMeetOfficialsOfficialDetail = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { otsMeetHeaderState } = useOtsMeetData();
  const { otsMeetSessionsState } = useOtsMeetSessionsData();
  const { meetOfficialSessionState, postMeetOfficialSessionByOtsMeetIdAndPersonId, putMeetOfficialSessionByOtsMeetIdAndPersonId, confirmMeetOfficialSessionSave } = useMeetOfficialSessionData();
  const { meetOfficialRoleTypeState } = useMeetOfficialRoleTypeData();
  const { navRoutes } = useNavRoutes();
  const { formState, errorState, handleSubmit, onFormValueChanged, onValueTextPairChanged, setFormData, setErrors, setIsDirty }
    = useForm(INITIAL_FORM_STATE, submitFormCallback, validate);
  const [state, setState] = useState(INITIAL_VIEW_STATE);

  const onSaveClicked = (e) => {
    if (e?.preventDefault) {
      e.preventDefault();
    }
    setIsDirty(true);
    handleSubmit();
  }

  const onBackClicked = (e) => {
    if (e?.preventDefault) {
      e.preventDefault();
    }

    navigate(navRoutes.OTS_MEET_OFFICIALS_OFFICIAL?.route, {
      state: {
        meetOfficialPersonId: formState.meetOfficialPersonId,
        firstName: formState.firstName,
        preferredName: formState.preferredName,
        middleName: formState.middleName,
        lastName: formState.lastName,
        meetOfficialMemberId: formState.meetOfficialMemberId,
        selectedValidMemberId: formState.selectedValidMemberId
      }
    });
  };

  useEffect(() => {
    if (location.state && otsMeetSessionsState.isArrayLoaded === true &&
      meetOfficialRoleTypeState.isArrayLoaded === true) {
      setFormData({
        ...formState,
        isEdit: location.state?.isEdit === true ? true : false,
        meetOfficialPersonId: location.state?.meetOfficialPersonId || Constants.DEFAULT_ID,
        firstName: location.state?.firstName || '',
        preferredName: location.state?.preferredName || '',
        middleName: location.state?.middleName || '',
        lastName: location.state?.lastName || '',
        meetOfficialMemberId: location.state?.meetOfficialMemberId || '',
        selectedValidMemberId: location.state?.selectedValidMemberId ?? false,
        meetSessionIds: location.state?.meetSessionIds ? formatMeetSessionIdsForMultiSelect(location.state?.meetSessionIds) : [],
        meetOfficialRoleTypeId: location.state?.meetOfficialRoleTypeId || Constants.DEFAULT_ID,
        isApprentice: location.state?.isEdit === true ? (location?.state?.isApprentice ?? '') : false
      }, true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [otsMeetSessionsState.isArrayLoaded, meetOfficialRoleTypeState.isArrayLoaded]);

  useEffect(() => {
    if (state.tryRedirect === true && meetOfficialSessionState.isSaved === true) {
      confirmMeetOfficialSessionSave();
      navigate(navRoutes.OTS_MEET_OFFICIALS_OFFICIAL?.route, {
        state: {
          meetOfficialPersonId: formState.meetOfficialPersonId,
          firstName: formState.firstName,
          preferredName: formState.preferredName,
          middleName: formState.middleName,
          lastName: formState.lastName,
          meetOfficialMemberId: formState.meetOfficialMemberId,
          selectedValidMemberId: formState.selectedValidMemberId,
          tryGet: true
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.tryRedirect, meetOfficialSessionState]);

  function validateSessionIsNotADupe() {
    for (let i = 0; i < meetOfficialSessionState.arrayData.length; i++) {
      if (meetOfficialSessionState.arrayData[i].meetOfficialRoleTypeId !== formState.meetOfficialRoleTypeId) {
        for (let j = 0; j < meetOfficialSessionState.arrayData[i].session.length; j++) {
          const dupe = formState.meetSessionIds.find(x => x.id === meetOfficialSessionState.arrayData[i].session[j].meetSessionId);
          if (dupe) {
            return false;
          }
        }
      }
    };

    return true;
  }

  function formatMeetSessionIdsForMultiSelect(meetSessionIds) {
    const formattedMeetSessionIds = [];
    for (let i = 0; i < meetSessionIds.length; i++) {
      formattedMeetSessionIds.push({ id: meetSessionIds[i].meetSessionId, name: `${meetSessionIds[i].sessionOrderId || ''} (${meetSessionIds[i].sessionType || ''} ${meetSessionIds[i].sessionDate ? formatDate(meetSessionIds[i].sessionDate) : ''})` });
    }
    return formattedMeetSessionIds;
  };

  function formatMeetSessionIdsForApiCall(meetSessionIds) {
    const formattedMeetSessionIds = [];
    for (let i = 0; i < meetSessionIds.length; i++) {
      formattedMeetSessionIds.push(meetSessionIds[i].id);
    }
    return formattedMeetSessionIds;
  };

  function submitFormCallback(formState) {
    const sessionIsNotADupe = validateSessionIsNotADupe();
    if (sessionIsNotADupe === true) {
      if (formState.isEdit === false) {
        const meetOfficialSessionObj = {
          meetSessionIds: formatMeetSessionIdsForApiCall(formState.meetSessionIds),
          meetOfficialRoleTypeId: formState.meetOfficialRoleTypeId,
          isApprentice: formState.isApprentice
        }
        postMeetOfficialSessionByOtsMeetIdAndPersonId(otsMeetHeaderState.objData?.otsMeetId, formState.meetOfficialPersonId, meetOfficialSessionObj);
      }
      else if (formState.isEdit === true) {
        const meetOfficialSessionObj = {
          meetSessionIds: formatMeetSessionIdsForApiCall(formState.meetSessionIds),
          meetOfficialRoleTypeId: formState.meetOfficialRoleTypeId,
          isApprentice: formState.isApprentice
        }
        putMeetOfficialSessionByOtsMeetIdAndPersonId(otsMeetHeaderState.objData?.otsMeetId, formState.meetOfficialPersonId, meetOfficialSessionObj);
      }
      setState({ ...state, tryRedirect: true });
    }
    else {
      setErrors({ ...errorState, general: 'A Meet Official is only allowed to work one Role per Session. This Meet Official has already been assigned a different Role Worked for at least one of the selected Sessions.' });
    }
  };

  return {
    otsMeetHeaderState,
    isLoading: meetOfficialSessionState.isArrayLoading || otsMeetSessionsState.isArrayLoading ||
      meetOfficialRoleTypeState.isArrayLoading,
    isSaving: meetOfficialSessionState.isSaving,
    formState,
    errorState,
    onFormValueChanged,
    onValueTextPairChanged,
    handleSubmit,
    Constants,
    onSaveClicked,
    onBackClicked
  };
}

export default useOtsMeetOfficialsOfficialDetail;