import { useEffect, useState } from 'react';
import { useNavigate, useLocation } from '../../../../common/wrappers/ReactRouterDom';

import validate from './MemberContactDetailValidation';

import useContactRelationshipTypeData from '../../../state/contactRelationship/UseContactRelationshipTypeData';

import useNavRoutes from '../../../../common/state/security/UseNavRoutes';
import usePersonGeneralData from '../../../../common/state/personGeneral/UsePersonGeneralData';

import useForm from '../../../../common/utils/UseForm';
import Constants from '../../../../common/utils/Constants';

const useMemberContactDetail = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { navRoutes } = useNavRoutes();
  const { personGeneralState, putPersonGeneralContact } = usePersonGeneralData();
  const { contactRelationshipTypeState } = useContactRelationshipTypeData();
  const { formState, errorState, isDirty, handleSubmit, updateFormState, setFormState, onValueTextPairChanged, setIsDirty
  } = useForm(getInitialFormState(), submitFormCallback, validate);
  const [state, setState] = useState(getInitialState());
  const [isPrimaryModalState, setIsPrimaryModalState] = useState(getInitialIsPrimaryModalState());

  const onSaveContact = (e) => {
    if (isDirty === false) {
      onCancelClicked(e);
    } else {
      handleSubmit(e);
    }
  };

  const onCancelClicked = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    navigate(navRoutes.MEMBER_CONTACTS?.route);
  };

  const onIsPrimaryModalCancelClicked = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    setIsDirty(true);
    setIsPrimaryModalState(getInitialIsPrimaryModalState());
  };

  const putPersonGeneralContacts = (doPrimaryCheck) => {
    if (personGeneralState.isSaving === false) {
      putPersonGeneralContact(personGeneralState.objData.personId, createPersonGeneralObject(doPrimaryCheck));
    }

    setState({
      ...state,
      tryRedirect: true
    });

    setIsPrimaryModalState(getInitialIsPrimaryModalState);
  };

  useEffect(() => {
    if (state.tryRedirect === true && personGeneralState.isObjLoading === false && personGeneralState.isSaving === false) {
      navigate(navRoutes.MEMBER_CONTACTS?.route);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [personGeneralState, state.tryRedirect]);

  useEffect(() => {
    if (contactRelationshipTypeState.isLoaded === true && personGeneralState.isObjLoaded === true) {
      if (location.state?.personContactId > 0) { // Edit Contact
        const targetContact = personGeneralState.objData.personContact.find(x => x.personContactId === location.state.personContactId);

        if (targetContact) {
          setFormState({
            ...formState,
            firstName: targetContact.firstName || '',
            lastName: targetContact.lastName || '',
            contactRelationshipTypeId: targetContact.contactRelationshipTypeId || Constants.DEFAULT_ID,
            contactRelationshipTypeName: targetContact.contactRelationshipType?.typeName || '',
            email: targetContact.emailAddress || '',
            isPrimary: targetContact.isPrimary === true,
            personContactId: targetContact.personContactId || Constants.DEFAULT_ID
          });

          setState({
            ...state,
            disablePrimaryCheckbox: targetContact.isPrimary === true
          });
        }
      } else if (personGeneralState.objData.personContact.length === 0) { // If this is the first add, force isPrimary to true
        setState({ ...state, disablePrimaryCheckbox: true });
        setFormState({ ...formState, isPrimary: true });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.state, personGeneralState, contactRelationshipTypeState]);

  function submitFormCallback(formState) {
    if (state.disablePrimaryCheckbox === false && formState.isPrimary === true) {
      const currentPrimary = personGeneralState.objData.personContact.find(x => x.isPrimary === true);

      if (currentPrimary) {
        setIsPrimaryModalState({
          ...isPrimaryModalState,
          displayPopUp: true,
          currentPrimaryInfo: `${currentPrimary.firstName} ${currentPrimary.lastName}, ${currentPrimary.emailAddress} (${currentPrimary.contactRelationshipType?.typeName || 'Unknown Contact Relationship'})`
        });
      } else {
        putPersonGeneralContacts(true);
      }
    } else {
      putPersonGeneralContacts(false);
    }
  };

  function createPersonGeneralObject(doPrimaryCheck) {
    const newPersonGeneralObj = JSON.parse(JSON.stringify(personGeneralState.objData));

    if (doPrimaryCheck === true) {
      for (let i = 0; i < newPersonGeneralObj.personContact.length; i++) {
        newPersonGeneralObj.personContact[i].isPrimary = false;
      }
    }

    const newPersonContact = {
      firstName: formState.firstName.trim(),
      lastName: formState.lastName.trim(),
      contactRelationshipTypeId: formState.contactRelationshipTypeId,
      emailAddress: formState.email.trim(),
      isPrimary: formState.isPrimary,
      isDeleted: false,
      contactRelationshipType: undefined
    };

    if (formState.personContactId > 0) {
      const index = newPersonGeneralObj.personContact.findIndex(e => e.personContactId === formState.personContactId);

      if (index >= 0) {
        newPersonGeneralObj.personContact[index] = {
          ...newPersonGeneralObj.personContact[index],
          ...newPersonContact
        };
      }
    } else {
      newPersonGeneralObj.personContact.push({
        personId: newPersonGeneralObj.personId,
        ...newPersonContact
      });
    }

    return newPersonGeneralObj;
  };

  function getInitialState() {
    return {
      disablePrimaryCheckbox: false,
      tryRedirect: false
    };
  };

  function getInitialFormState() {
    return {
      firstName: '',
      lastName: '',
      contactRelationshipTypeId: Constants.DEFAULT_ID,
      contactRelationshipTypeName: '',
      email: '',
      isPrimary: false,
      personContactId: Constants.DEFAULT_ID
    };
  };

  function getInitialIsPrimaryModalState() {
    return {
      displayPopUp: false,
      currentPrimaryInfo: '',
      modalTitle: 'Change the primary emergency contact?'
    };
  };

  return {
    state,
    formState,
    errorState,
    personGeneralState,
    contactRelationshipTypeState,
    isPrimaryModalState,
    onFormValueChanged: updateFormState,
    onValueTextPairChanged,
    onSaveContact,
    onCancelClicked,
    onIsPrimaryModalCancelClicked,
    putPersonGeneralContacts,
    DEFAULT_ID: Constants.DEFAULT_ID
  };
};

export default useMemberContactDetail;