import { useEffect, useState } from 'react';
import useOtsCertificationRuleData from '../../../../state/otsCertificationRule/UseOtsCertificationRuleData';
import useOtsCertificationApplicationData from '../../../../state/otsCertificationApplication/UseOtsCertificationApplicationData';

const useStatus = (personPositionCertificationProgressionId) => {
  const [ gridData, setGridData ] = useState([]);
  const [ positionCertificationProgression, setPositionCertificationProgression ] = useState({});
  const { otsCertificationApplicationState } = useOtsCertificationApplicationData();
  const { getOtsCertificationRulesForPersonProgression } = useOtsCertificationRuleData();
  
  useEffect(() => {
    getOtsCertificationRulesForPersonProgression(personPositionCertificationProgressionId)
      .then(data => {
        //const sections = formatSections(data.arrayData);
        setGridData(data.arrayData);
      });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [personPositionCertificationProgressionId]);
  
  useEffect(() => {
    if (personPositionCertificationProgressionId > 0) {
      const found = otsCertificationApplicationState?.arrayData?.find(x => x.personPositionCertificationProgressionId === personPositionCertificationProgressionId);
      if (found) {
        setPositionCertificationProgression({...found});
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [personPositionCertificationProgressionId]);

  return {
    gridData,
    positionCertificationProgression
  };
};

export default useStatus;