import React, { Fragment } from 'react';

import useOtsMeetInfo from './UseOtsMeetInfo';

import Headings from '../../../../../common/components/headings/Headings';
import Input from '../../../../../common/components/inputs/Input';
import YesNoSwitch from '../../../../../common/components/yesNoSwitch/YesNoSwitch';
import PrimaryButton from '../../../../../common/components/buttons/PrimaryButton';
import SecondaryButton from '../../../../../common/components/buttons/SecondaryButton';
import PopUpModal from '../../../../../common/components/dialogs/PopUpModal';

import Constants from '../../../../../common/utils/Constants';

import global from '../../../../../common/components/GlobalStyle.module.css';

const OtsMeetInfo = () => {
  const { otsMeetState, handleSubmit, formState, errorState,
    onFormValueChanged, resetForm } = useOtsMeetInfo();

  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12">
          <Headings.H3>Meet Info</Headings.H3>
        </div>
      </div>
      <form onSubmit={handleSubmit} noValidate>
        <div className="row usas-extra-top-margin">
          <div className="col-xs-12 col-sm-6 col-md-4">
            <YesNoSwitch
              label="Officials Qualifying Meet (OQM)?*"
              name="isOfficialsQualifyingMeet"
              checked={formState.isOfficialsQualifyingMeet}
              error={errorState.isOfficialsQualifyingMeet}
              message={errorState.isOfficialsQualifyingMeet}
              onChange={(value) => { onFormValueChanged('isOfficialsQualifyingMeet', value); }} />
          </div>
          <div className={formState.isOfficialsQualifyingMeet === true ? global.DisplayComponent : global.HideComponent}>
            <div className="col-xs-12 col-sm-6 col-md-4">
              <Input
                label="OQM Number*"
                name="oqmNumber"
                value={formState.oqmNumber}
                error={errorState.oqmNumber}
                message={errorState.oqmNumber}
                onChange={(value) => { onFormValueChanged('oqmNumber', value); }} />
            </div>
          </div>
        </div>
        <div className="row usas-extra-top-margin usas-extra-bottom-margin">
          <div className="col-xs-12 usas-extra-top-margin">
            <PrimaryButton type="submit">Save</PrimaryButton>&nbsp;
            <SecondaryButton type="button" onClick={resetForm}>Cancel</SecondaryButton>
          </div>
        </div>
      </form>
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={otsMeetState.isObjLoading} />
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.SAVING_MSG}
        displayPopUp={otsMeetState.isSaving} />
    </Fragment>
  );
};

export default OtsMeetInfo;