import { useState, useEffect } from 'react';
import { useLocation, useNavigate } from '../../../../common/wrappers/ReactRouterDom';

import validate from './MemberIneligibleToCompeteDetailValidation';

import useIneligibleReasonData from '../../../state/ineligibleReason/UseIneligibleReasonData';

import useNavRoutes from '../../../../common/state/security/UseNavRoutes';
import useBasePersonData from '../../../../common/state/personBase/UseBasePersonData';

import useForm from '../../../../common/utils/UseForm';
import Constants, { DEFAULT_MAX_DATE } from '../../../../common/utils/Constants';
import { formatDate } from '../../../../common/utils/DateFunctions';

const INITIAL_FORM_STATE = {
  personIneligibleReasonId: Constants.DEFAULT_ID,
  ineligibleReasonId: Constants.DEFAULT_ID,
  ineligibleReasonDescription: '',
  effectiveDate: Constants.BLANK_DATE_STRING,
  expirationDate: Constants.BLANK_DATE_STRING,
  isPermanent: false,
  itemsAssigned: []
};

const INITIAL_UI_STATE = {
  tryRedirect: false
};

const useMemberIneligibleToCompeteDetail = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { navRoutes } = useNavRoutes();
  const { basePersonState, putBasePerson } = useBasePersonData();
  const { ineligibleReasonState } = useIneligibleReasonData();
  const { formState, errorState, handleSubmit, updateFormState, onValueTextPairChanged, setFormState, setFormData
  } = useForm(INITIAL_FORM_STATE, submitFormCallback, validate);
  const [uiState, setUiState] = useState(INITIAL_UI_STATE);

  const onCancelClicked = () => {
    navigate(navRoutes.MEMBER_INELIGIBLE_TO_COMPETE?.route);
  };

  const onIsPermanentChanged = (value) => {
    setFormState({
      ...formState,
      isPermanent: value,
      expirationDate: value === true ? DEFAULT_MAX_DATE : Constants.BLANK_DATE_STRING
    });
  };

  useEffect(() => {
    if (uiState.tryRedirect === true && basePersonState.isObjLoading === false && basePersonState.isSaving === false) {
      navigate(navRoutes.MEMBER_INELIGIBLE_TO_COMPETE?.route);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [basePersonState, uiState.tryRedirect]);

  useEffect(() => {
    if (location.state?.personIneligibleReason) {
      const personIneligibleReason = location.state.personIneligibleReason;

      setFormData({
        ...formState,
        personIneligibleReasonId: personIneligibleReason.personIneligibleReasonId || Constants.DEFAULT_ID,
        ineligibleReasonId: personIneligibleReason.ineligibleReasonId || Constants.DEFAULT_ID,
        ineligibleReasonDescription: personIneligibleReason.ineligibleReason?.reasonDescription || '',
        effectiveDate: personIneligibleReason.effectiveDate ? formatDate(personIneligibleReason.effectiveDate) : Constants.BLANK_DATE_STRING,
        expirationDate: personIneligibleReason.expirationDate ? formatDate(personIneligibleReason.expirationDate) : Constants.BLANK_DATE_STRING,
        isPermanent: personIneligibleReason.expirationDate && formatDate(personIneligibleReason.expirationDate) === DEFAULT_MAX_DATE ? true : false,
        itemsAssigned: []
      }, true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function submitFormCallback(formState) {
    if (basePersonState.isSaving === false) {
      const basePersonToPut = createPutBasePersonObject(formState);

      if (basePersonToPut !== null) {
        putBasePerson(basePersonState.objData.personId, basePersonToPut);
        setUiState({
          ...uiState,
          tryRedirect: true
        });
      } else {
        onCancelClicked();
      }
    }
  };

  // Creates a copy of the basePersonState.objData and makes changes to it based on formState
  function createPutBasePersonObject(formState) {
    const basePersonToPut = JSON.parse(JSON.stringify(basePersonState.objData));

    if (basePersonToPut.usasPerson === null || basePersonToPut.usasPerson === undefined) {
      basePersonToPut.usasPerson = createUsasPersonIfUndefinedOrNull();
    }

    const ineligibleReasonIndex = basePersonToPut.usasPerson.personIneligibleReason.findIndex(x => x.personIneligibleReasonId === formState.personIneligibleReasonId);

    if (ineligibleReasonIndex >= 0) {
      basePersonToPut.usasPerson.personIneligibleReason[ineligibleReasonIndex].ineligibleReasonId = formState.ineligibleReasonId;
      basePersonToPut.usasPerson.personIneligibleReason[ineligibleReasonIndex].effectiveDate = formState.effectiveDate;
      basePersonToPut.usasPerson.personIneligibleReason[ineligibleReasonIndex].expirationDate = formState.isPermanent === true ? Constants.DEFAULT_MAX_DATE : formState.expirationDate;
    } else {
      basePersonToPut.usasPerson.personIneligibleReason.push({
        personId: basePersonState.objData.personId,
        ineligibleReasonId: formState.ineligibleReasonId,
        effectiveDate: formState.effectiveDate,
        expirationDate: formState.isPermanent === true ? Constants.DEFAULT_MAX_DATE : formState.expirationDate
      });
    }

    return basePersonToPut;
  };

  function createUsasPersonIfUndefinedOrNull() {
    return {
      personId: basePersonState.objData.personId,
      maidenName: null,
      suffix: null,
      maidenNameUsedAsLastName: false,
      birthDateConfirmed: false,
      isUsCitizen: false,
      hispanicOrLatinoTypeId: null,
      hispanicOrLatinoType: undefined,
      personDisability: [],
      personEthnicity: [],
      personNarrative: [],
      personIneligibleReason: []
    };
  };

  return {
    isEdit: location.state?.personIneligibleReason ? true : false,
    basePersonState,
    formState,
    errorState,
    uiState,
    ineligibleReasonState,
    onFormValueChanged: updateFormState,
    onIsPermanentChanged,
    onValueTextPairChanged,
    handleSubmit,
    onCancelClicked
  };
};

export default useMemberIneligibleToCompeteDetail;