import { useEffect } from 'react';

import validate from './OtsMeetInfoValidation';

import useOtsMeetData from '../../../../state/officialsTrackingSystemMeet/UseOtsMeetData';

import UseForm from '../../../../../common/utils/UseForm';

const useOtsMeetInfo = () => {
  const { otsMeetState, otsMeetHeaderState, getOtsMeet, putOtsMeet } = useOtsMeetData();
  const { formState, errorState, handleSubmit, updateFormState, setFormData,
    resetForm } = UseForm(getInitialFormState(), submitFormCallback, validate);

  useEffect(() => {
    if (otsMeetHeaderState.isObjLoaded === true && (otsMeetState.isObjLoaded === false ||
      (otsMeetState.objData?.otsMeetId !== otsMeetHeaderState.objData?.otsMeetId))
      && otsMeetState.isObjLoading === false) {
      getOtsMeet(otsMeetHeaderState.objData?.otsMeetId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [otsMeetState, otsMeetHeaderState])

  useEffect(() => {
    if (otsMeetState.isObjLoaded === true) {
      setFormData({
        ...formState,
        isOfficialsQualifyingMeet: otsMeetState.objData?.isOfficialsQualifyingMeet ?? '',
        oqmNumber: otsMeetState.objData?.oqmNumber || ''
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [otsMeetState])

  function submitFormCallback(formState) {
    const otsMeetObj = {
      otsMeetId: otsMeetState.objData?.otsMeetId,
      meetId: otsMeetState.objData?.meetId,
      isOfficialsQualifyingMeet: formState.isOfficialsQualifyingMeet,
      oqmNumber: formState.isOfficialsQualifyingMeet === true ? formState.oqmNumber.trim() : null
    }
    putOtsMeet(otsMeetState.objData?.otsMeetId, otsMeetObj);
  };

  function getInitialFormState() {
    return {
      isOfficialsQualifyingMeet: '',
      oqmNumber: ''
    };
  };

  return {
    otsMeetState,
    handleSubmit,
    formState,
    errorState,
    onFormValueChanged: updateFormState,
    resetForm
  };
};

export default useOtsMeetInfo;