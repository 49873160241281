import React, { createContext, useState } from 'react';

import ContactRelationshipTypeData from './ContactRelationshipTypeData';

export const ContactRelationshipTypeStateContext = createContext();

const ContactRelationshipTypeContextProvider = ({ children }) => {
  const stateHook = useState(ContactRelationshipTypeData.INITIAL_STATE);

  return (
    <ContactRelationshipTypeStateContext.Provider value={stateHook}>
      {children}
    </ContactRelationshipTypeStateContext.Provider>
  );
};

export default ContactRelationshipTypeContextProvider;