import { Fragment } from 'react';

import useMemberIneligibleToCompeteDetail from './UseMemberIneligibleToCompeteDetail';

import PrimaryButton from '../../../../common/components/buttons/PrimaryButton';
import SecondaryButton from '../../../../common/components/buttons/SecondaryButton';
import DatePicker from '../../../../common/components/datepickers/DatePicker';
import PopUpModal from '../../../../common/components/dialogs/PopUpModal';
import Dropdown from '../../../../common/components/dropdowns/Dropdown';
import Headings from '../../../../common/components/headings/Headings';
import YesNoSwitch from '../../../../common/components/yesNoSwitch/YesNoSwitch';
import ReadOnly from '../../../../common/components/readOnly/ReadOnly';

import Constants from '../../../../common/utils/Constants';

const MemberIneligibleToCompeteDetail = () => {
  const {
    isEdit,
    basePersonState,
    formState,
    errorState,
    ineligibleReasonState,
    onFormValueChanged,
    onIsPermanentChanged,
    onValueTextPairChanged,
    handleSubmit,
    onCancelClicked
  } = useMemberIneligibleToCompeteDetail();

  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12">
          <Headings.H3>{isEdit ? 'Edit' : 'Add'} Member Ineligible to Compete Reason</Headings.H3>
        </div>
      </div>
      <form onSubmit={handleSubmit} noValidate>
        <div className="row">
          <div className="col-xs-12 col-sm-6 col-md-4">
            <Dropdown
              label="Ineligible To Compete Reason*"
              name="ineligibleReasonId"
              value={formState.ineligibleReasonId}
              error={errorState.ineligibleReasonId}
              message={errorState.ineligibleReasonId}
              isLoading={ineligibleReasonState.isLoading}
              options={ineligibleReasonState.dataAsOptionsWithNames}
              onChange={(newValue, newValueLabel) => { onValueTextPairChanged(newValue, 'ineligibleReasonId', newValueLabel, 'ineligibleReasonDescription') }} />
          </div>
          <div className="col-xs-12 col-sm-6 col-md-4">
            <DatePicker
              label="Ineligible to Compete Start Date*"
              name="effectiveDate"
              value={formState.effectiveDate}
              error={errorState.effectiveDate}
              message={errorState.effectiveDate}
              countOfYears={30}
              startYearOffset={-15}
              onChange={(value) => { onFormValueChanged('effectiveDate', value); }} />
          </div>
          {formState.isPermanent === false &&
            <div className="col-xs-12 col-sm-6 col-md-4">
              <DatePicker
                label="Ineligible to Compete End Date*"
                name="expirationDate"
                value={formState.expirationDate}
                error={errorState.expirationDate}
                message={errorState.expirationDate}
                countOfYears={30}
                startYearOffset={-15}
                onChange={(value) => { onFormValueChanged('expirationDate', value); }} />
            </div>
          }
          <div className="col-xs-12 col-sm-6 col-md-4">
            <YesNoSwitch
              label="Is Ineligibility Permanent?"
              name="isPermanent"
              checked={formState.isPermanent === true}
              error={errorState.isPermanent}
              message={errorState.isPermanent}
              onChange={(value) => { onIsPermanentChanged(value); }} />
          </div>
        </div>
        <div className="row usas-extra-top-margin usas-extra-bottom-margin">
          <div className="col-xs-12 usas-extra-top-margin">
            <PrimaryButton type="submit">Save</PrimaryButton>&nbsp;
            <SecondaryButton type="button" onClick={onCancelClicked}>Back</SecondaryButton>
          </div>
        </div>
      </form>
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={basePersonState.isObjLoading || ineligibleReasonState.isLoading} />
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.SAVING_MSG}
        displayPopUp={basePersonState.isSaving} />
    </Fragment>
  );
};

export default MemberIneligibleToCompeteDetail;