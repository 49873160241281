import { isValid5DigitZip, isValid9DigitZip, isValidCanadianZip, isValidAddressLine, validateAddress } from '../../../../common/utils/validation';
import Constants from '../../../../common/utils/Constants';

export const localValidate = async (formState) => {
  let errors = {};

  let addressError = false;
  //Address Type
  if (formState.addressTypeId < 0) {
    addressError = true;
    errors.addressTypeId = 'Address Type is required';
  }
  
  //Country
  if (formState.country === '') {
    addressError = true;
    errors.country = 'Country is required';
  }
  
  //Address Line 1
  if (formState.address1.trim() === '') {
    addressError = true;
    errors.address1 = 'Address Line 1 is required';
  }
  else if (!isValidAddressLine(formState.address1.trim())) {
    addressError = true;
    errors.address1 = 'Address Line 1 cannot exceed 100 characters. Letters, numbers, number signs, spaces, periods, dashes, slashes, and apostrophes are allowed.';
  }
  
  //Address Line 2
  if (formState.address2.trim() !== '') {
    if (!isValidAddressLine(formState.address2.trim())) {
      addressError = true;
      errors.address2 = 'Address Line 2 cannot exceed 100 characters. Letters, numbers, number signs, spaces, periods, dashes, slashes, and apostrophes are allowed.';
    }
  }
  
  //City
  if (formState.city.trim() === '') {
    addressError = true;
    errors.city = 'City is required';
  }
  else if (!isValidAddressLine(formState.city.trim())) {
    addressError = true;
    errors.city = 'City cannot exceed 100 characters. Letters, numbers, number signs, spaces, periods, dashes, slashes, and apostrophes are allowed.';
  }
  
  //State
  // formState.stateCode !== '  ' is there for non-NA countries -TL
  if (formState.state === '' && formState.stateCode !== '  ') {
    addressError = true;
    errors.state = 'State is required';
  } else if (formState.stateCode === Constants.DEFAULT_ID) {
    addressError = true;
    errors.state = 'A valid State is required';
  }
  
  //Zip Code
  if (formState.postalCode.trim() === '') {
    addressError = true;
    errors.postalCode = 'Zip Code is required';
  } else if ((formState.country === 'USA') && !isValid5DigitZip(formState.postalCode.trim()) && !isValid9DigitZip(formState.postalCode.trim())) {
    addressError = true;
    errors.postalCode = 'USA Zip Code must be 5 digits, or 9 digits in XXXXX-XXXX format';
  } else if ((formState.country === 'CAN') && !isValidCanadianZip(formState.postalCode.trim())) {
    addressError = true;
    errors.postalCode = 'Canadian Zip Code must be valid and in A1A 1A1 format';
  } else if ((formState.country === 'MEX') && !isValid5DigitZip(formState.postalCode.trim())) {
    addressError = true;
    errors.postalCode = 'Mexican Zip Code must be 5 digits';
  }

  if (addressError === false
    && formState.showedAddressSuggestion === false) {
    formState.validatedAddress = await validateAddress(formState.address1?.trim(), formState.address2?.trim(), formState.city?.trim(), formState.stateCode, formState.postalCode?.trim());
    if (formState.validatedAddress.isValid === true
      && (
        formState.address1 !== formState.validatedAddress.address1
        || formState.address2 !== formState.validatedAddress.address2
        || formState.city !== formState.validatedAddress.city
        || formState.stateCode !== formState.validatedAddress.stateCode
        || formState.postalCode !== formState.validatedAddress.postalCode
      )
    ) {
      if (formState.address1 !== formState.validatedAddress.address1) {
        errors.address1 = 'See Address Suggestion';
      }
      if (formState.address2 !== formState.validatedAddress.address2) {
        errors.address2 = 'See Address Suggestion';
      }
      if (formState.city !== formState.validatedAddress.city) {
        errors.city = 'See Address Suggestion';
      }
      if (formState.stateCode !== formState.validatedAddress.stateCode) {
        errors.stateCode = 'See Address Suggestion';
      }
      if (formState.postalCode !== formState.validatedAddress.postalCode) {
        errors.postalCode = 'See Address Suggestion';
      }
    }
  }

  return errors;
};

const MemberContactAddressDetailValidation = async (formState) => {
  let errors = await localValidate(formState);

  return errors ? errors : {};
};

export default MemberContactAddressDetailValidation;