import { useEffect, useState } from 'react';
import useOtsCertificationRuleData from '../../../../state/otsCertificationRule/UseOtsCertificationRuleData';

const useRulesList = (positionCertificationProgressions) => {
  const [rules, setRules] = useState([]);
  const { getOtsCertificationRulesForProgressions } = useOtsCertificationRuleData();

  const formatRules = (rulesData) => {
    const rules = [];

    rulesData.forEach(ruleData => {
      let newRequirementTypes = [];
      ruleData.certificationRule?.forEach(item => {
        let newRequirementTypesMatchIndex = newRequirementTypes?.findIndex(x => x.requirementType === item.requirementType);
        if (newRequirementTypesMatchIndex >= 0) {       
          newRequirementTypes[newRequirementTypesMatchIndex].rules.push({ ...item });
        }
        else {
          newRequirementTypes.push({ requirementType: item.requirementType, rules: [{ ...item }] });
        }
      });
      rules.push({ ...ruleData, newRequirementTypes: newRequirementTypes });
    })

    return rules;
  }

  useEffect(() => {
    const positionCertificationProgressionIds = positionCertificationProgressions.map(x => x.positionCertificationProgressionId);
    const positionCertificationProgressionIdsString = positionCertificationProgressionIds.toString();
    getOtsCertificationRulesForProgressions(positionCertificationProgressionIdsString)
      .then(data => {
        const newRules = formatRules(data.arrayData);
        setRules(newRules);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [positionCertificationProgressions]);

  return {
    rules
  };
};

export default useRulesList;