import { Fragment } from 'react';

import useStatus from './UseStatus';

import Grid from './Grid';
import CertificationHeader from '../../../../officialsTrackingSystem/certificationApplication/CertificationHeader';

import Headings from '../../../../../../common/components/headings/Headings';
import SecondaryButton from '../../../../../../common/components/buttons/SecondaryButton';

import { formatDate } from '../../../../../../common/utils/DateFunctions';
import Constants from '../../../../../../common/utils/Constants';

import global from '../../../../../../common/components/GlobalStyle.module.css';

const CertificationHeaderForOlderExistingCerts = ({ position }) => (
  <Fragment>
    <div className="col-xs-12 col-sm-6">
      {position.effectiveDate && (<p className={global.HeaderText}>
        <strong>Date Initiated:</strong>&nbsp;{formatDate(position.effectiveDate)}
      </p>)}
    </div>
    <div className="col-xs-12 col-sm-6">
      <p className={global.HeaderText}>
        <strong>Position:</strong>&nbsp;{position.positionTypeName}
      </p>
    </div>
    <div className="col-xs-12 col-sm-6">
      <p className={global.HeaderText}>
        <strong>Level:</strong>&nbsp;{position.levelName}
      </p>
    </div>
    <div className="col-xs-12 col-sm-6">
      <p className={global.HeaderText}>
        <strong>Application Status:</strong>&nbsp;{position.status || ''}
      </p>
    </div>
  </Fragment>
)

const Status = () => {
  const { personPositionCertificationProgressionId, certificationPublicNote, publicNotes, position, onCancelClicked, gridData,
    positionCertificationProgression } = useStatus();

  return (
    <>
      <div className="row usas-extra-top-margin">
        <div className="col-xs-12">
          <Headings.H3>National Certification Application History</Headings.H3>
        </div>
      </div>
      {personPositionCertificationProgressionId !== Constants.DEFAULT_ID &&
        <CertificationHeader positionCertificationProgression={positionCertificationProgression} showApplicationStatus={true} />}
      <div className="row">
        {personPositionCertificationProgressionId === Constants.DEFAULT_ID &&
          <CertificationHeaderForOlderExistingCerts position={position} />}
        <div className="col-xs-12">
          <p className={global.HeaderText}>
            <strong>Reviewer Notes:</strong>&nbsp;{!publicNotes && !certificationPublicNote ? 'None Provided' : `${certificationPublicNote || ''}${certificationPublicNote ? '.' : ''} ${publicNotes || ''}`}
          </p>
        </div>
      </div>
      {Array.isArray(gridData) && personPositionCertificationProgressionId !== Constants.DEFAULT_ID && (
        <div className="row usas-extra-bottom-margin">
          <div className="col-xs-12">
            <Grid gridData={gridData} />
          </div>
        </div>
      )}

      <div className="row usas-extra-top-margin">
        <div className="col-xs-12 usas-extra-top-margin">
          <SecondaryButton type="button" onClick={onCancelClicked}>Back</SecondaryButton>
        </div>
      </div>
    </>
  );
};

export default Status;