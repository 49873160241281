import { useState, useEffect } from 'react';
import { useNavigate } from '../../../../common/wrappers/ReactRouterDom';

import { isPersonObjectDataCurrent } from '../utils/MemberUtils';

import useNavRoutes from '../../../../common/state/security/UseNavRoutes';
import useBasePersonData from '../../../../common/state/personBase/UseBasePersonData';
import usePersonGeneralNationalTeamData from '../../../../common/state/personGeneralNationalTeam/UsePersonGeneralNationalTeamData';
import useContactRelationshipData from '../../../../common/state/contactRelationshipType/UseContactRelationshipTypesData';

import Constants from '../../../../common/utils/Constants';
// import { formatDate } from '../../../../common/utils/DateFunctions';
import { /*evaluateBirthDate,*/ formatPhoneNumber } from '../../../../common/utils/validation';

const SELF_CONTACT_NAME = 'Self';

const useNationalTeamContacts = () => {
  const navigate = useNavigate();
  const { navRoutes } = useNavRoutes();
  const { basePersonState, getBasePerson } = useBasePersonData();
  const { personGeneralNationalTeamState, getPersonGeneralNationalTeam, deletePersonNationalTeamContact, deletePersonNationalTeamContactPhone, deletePersonNationalTeamContactAddress,
    confirmSave } = usePersonGeneralNationalTeamData();
  const [viewState, setViewState] = useState({ contactDisplayList: [] });
  const [deleteModalState, setDeleteModalState] = useState(getInitialDeleteModalState());
  const { contactRelationshipTypeState, getContactRelationshipTypes } = useContactRelationshipData();

  function getPrimaryPhoneNumber(phoneArray) {
    if (Array.isArray(phoneArray) && phoneArray.length > 0) {
      const primaryPhone = phoneArray.find(e => e.isPrimary === true);

      if (primaryPhone) {
        return primaryPhone.phoneNumber ? formatPhoneNumber(primaryPhone.phoneNumber) : '';
      } else {
        return phoneArray[0]?.phoneNumber ? formatPhoneNumber(phoneArray[0]?.phoneNumber) : '';
      }
    } else {
      return '';
    }
  };

  function createContactDisplayList(personObj, personContacts) {
    // const memberBirthDate = formatDate(personObj.birthDate);
    // const birthDateObj = evaluateBirthDate(memberBirthDate);
    const contactList = [];

    if (Array.isArray(personContacts) && personContacts.length > 0) {
      for (const contact of personContacts) {
        if (contact.contactRelationshipType?.typeName === SELF_CONTACT_NAME) {
          contactList.unshift({
            id: contact.personNationalTeamContactId || Constants.DEFAULT_ID,
            name: `${contact.firstName || ''} ${contact.lastName || ''}`,
            relationship: contact.contactRelationshipType?.typeName || '',
            email: contact.emailAddress || '',
            phoneNumber: getPrimaryPhoneNumber(contact.personNationalTeamContactPhone),
            isPrimaryDisplay: '',
            canEdit: true, // birthDateObj.isJunior === true || birthDateObj.isAdult === true,
            canDelete: false,
            contactPhones: contact.personNationalTeamContactPhone,
            contactAddresses: contact.personNationalTeamContactAddress
          });
        } else {
          contactList.push({
            id: contact.personNationalTeamContactId || Constants.DEFAULT_ID,
            name: `${contact.firstName || ''} ${contact.lastName || ''}`,
            relationship: contact.contactRelationshipType?.typeName || '',
            email: contact.emailAddress || '',
            phoneNumber: getPrimaryPhoneNumber(contact.personNationalTeamContactPhone),
            isPrimaryDisplay: contact.isPrimary === true ? 'Yes' : 'No',
            canEdit: true,
            canDelete: contact.isPrimary === true ? false : true,
            contactPhones: contact.personNationalTeamContactPhone,
            contactAddresses: contact.personNationalTeamContactAddress
          });
        }
      }
    }

    return contactList;
  };


  function getInitialDeleteModalState() {
    return {
      personNationalTeamContactAddressId: Constants.DEFAULT_ID,
      personNationalTeamContactPhoneId: Constants.DEFAULT_ID,
      personNationalTeamContactId: Constants.DEFAULT_ID,
      targetObjectName: '',
      modalTitle: 'Place Holder',
      displayPopUp: false
    };
  };


  const onAddPerson = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    navigate(navRoutes.MEMBER_NTCONTACTS_DETAIL.route, { state: { personNationalTeamContactId: Constants.DEFAULT_ID } });
  };

  const onEditPerson = (e, personNationalTeamContactId) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    navigate(navRoutes.MEMBER_NTCONTACTS_DETAIL.route, { state: { personNationalTeamContactId } });
  };

  const onAddEditAddress = (e, personNationalTeamContactAddressId, personNationalTeamContactId) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    navigate(navRoutes.MEMBER_NT_CONTACTS_DETAIL_ADDRESS?.route, { state: { personNationalTeamContactAddressId, personNationalTeamContactId } });
  };

  const onAddEditPhone = (e, personNationalTeamContactPhoneId, personNationalTeamContactId) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    navigate(navRoutes.MEMBER_NT_CONTACTS_DETAIL_PHONE?.route, { state: { personNationalTeamContactPhoneId, personNationalTeamContactId } });
  };

  const onDeletePerson = (e, personNationalTeamContactId, targetObjectName) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    setDeleteModalState({
      ...deleteModalState,
      modalTitle: 'Delete this Contact?',
      personNationalTeamContactId,
      targetObjectName,
      displayPopUp: true
    });
  };

  const onDeleteAddress = (e, personNationalTeamContactAddressId, targetObjectName) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    setDeleteModalState({
      ...deleteModalState,
      modalTitle: 'Delete this Address?',
      personNationalTeamContactAddressId,
      targetObjectName,
      displayPopUp: true
    });
  };

  const onDeletePhone = (e, personNationalTeamContactPhoneId, targetObjectName) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    setDeleteModalState({
      ...deleteModalState,
      modalTitle: 'Delete this Phone Number?',
      personNationalTeamContactPhoneId,
      targetObjectName,
      displayPopUp: true
    });
  };

  const onDeleteModalConfirmClicked = () => {
    if (deleteModalState.personNationalTeamContactId > 0) {
      deletePersonNationalTeamContact(deleteModalState.personNationalTeamContactId);
    } else if (deleteModalState.personNationalTeamContactAddressId > 0) {
      deletePersonNationalTeamContactAddress(deleteModalState.personNationalTeamContactAddressId);
    } else if (deleteModalState.personNationalTeamContactPhoneId > 0) {
      deletePersonNationalTeamContactPhone(deleteModalState.personNationalTeamContactPhoneId);
    }

    setDeleteModalState(getInitialDeleteModalState());
  };

  const onDeleteModalCanceledClicked = () => {
    setDeleteModalState(getInitialDeleteModalState());
  };


  useEffect(() => {
    if (contactRelationshipTypeState?.isArrayLoaded !== true && contactRelationshipTypeState?.isArrayLoading !== true
      && Array.isArray(contactRelationshipTypeState?.arrayData) === true && contactRelationshipTypeState?.arrayData?.length === 0) {
      getContactRelationshipTypes();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contactRelationshipTypeState]);

  useEffect(() => {
    if (personGeneralNationalTeamState.isObjLoaded === false
      || !isPersonObjectDataCurrent(basePersonState.objData.personId, personGeneralNationalTeamState.objData)) {
      getPersonGeneralNationalTeam(basePersonState.objData.personId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [basePersonState]);

  useEffect(() => {
    if (basePersonState.isObjLoaded === true && personGeneralNationalTeamState.isObjLoaded === true
      && isPersonObjectDataCurrent(basePersonState.objData.personId, personGeneralNationalTeamState.objData)) {
      setViewState({
        ...viewState,
        contactDisplayList: createContactDisplayList(basePersonState.objData, personGeneralNationalTeamState.objData.personNationalTeamContact || [])
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [personGeneralNationalTeamState]);

  useEffect(() => { // base & general share self contact values
    if (personGeneralNationalTeamState.isSaved === true) {
      getBasePerson(personGeneralNationalTeamState.objData.personId);
      confirmSave();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [personGeneralNationalTeamState.isSaved]);

  return {
    deleteModalState,
    viewState,
    basePersonState,
    personGeneralNationalTeamState,
    onAddPerson,
    onEditPerson,
    onAddEditAddress,
    onAddEditPhone,
    onDeletePerson,
    onDeleteAddress,
    onDeletePhone,
    onDeleteModalConfirmClicked,
    onDeleteModalCanceledClicked
  };
};

export default useNationalTeamContacts;