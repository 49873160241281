import useExpandedRow from '../../../../../common/components/grids/UseExpandedRow';
import GridLarge from './GridLarge';
import GridSmall from './GridSmall';

const Grid = ({ gridData }) => {
  const { expandedId, onClick } = useExpandedRow();
  
  return (
    <>
      <GridLarge
        gridData={gridData}
        expandedId={expandedId}
        onExpandClicked={onClick} />
      <GridSmall
        gridData={gridData}
        expandedId={expandedId}
        onExpandClicked={onClick} />
    </>
  )
};

export default Grid;