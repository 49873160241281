import { useEffect, useState } from 'react';

import validate from './MemberInfoValidation';

import { createUsasPersonIfUndefinedOrNull } from '../utils/MemberUtils';

import usePersonFilesBirthCertificateData from '../../../state/member/memberFilesBirthCertificate/UsePersonFilesBirthCertificateData';
import { PersonHttpHelper, NO_DATA_MESSAGE } from '../../../../common/utils/HttpHelper';

import usePersonGeneralData from '../../../../common/state/personGeneral/UsePersonGeneralData';
import useBasePersonData from '../../../../common/state/personBase/UseBasePersonData';
import useGenderIdentityTypeData from '../../../../common/state/genderIdentity/UseGenderIdentityTypeData';

import useBaseMemberData from '../../../state/member/baseMember/UseBaseMemberData';

import useForm from '../../../../common/utils/UseForm';
import { evaluateBirthDate } from '../../../../common/utils/validation';
import Constants from '../../../../common/utils/Constants';
import { formatDate } from '../../../../common/utils/DateFunctions';

const suffixOptions = [
  { id: Constants.DEFAULT_ID, name: "--", value: '' },
  { id: 1, name: "Sr.", value: 'Sr.' },
  { id: 2, name: "Jr.", value: 'Jr.' },
  { id: 3, name: "II", value: 'II' },
  { id: 4, name: "III", value: 'III' },
  { id: 5, name: "IV", value: 'IV' },
  { id: 6, name: "V", value: 'V' }
];

const DEFAULT_GENDER_IDENTITY = 'Not Submitted';

const useMemberInfo = () => {
  const { basePersonState, putBasePerson, confirmBasePersonSave } = useBasePersonData();
  const { personGeneralState, getPersonGeneral } = usePersonGeneralData();
  const { memberFilesBirthCertificateState, getFileNameFromSavedUploadUrl, getFileNameFromUrl,
    postMemberFilesBirthCertificate, GET_MEMBER_FILES_BIRTH_CERTIFICATE_PATH } = usePersonFilesBirthCertificateData();
  const { genderIdentityState, getGenderIdentityTypes } = useGenderIdentityTypeData();
  const [radioButtonListState, setRadioButtonListState] = useState({ radiobuttonlistGenderIdentity: [] });
  const { handleSubmit, updateFormState, onValueTextPairChanged, formState, setFormData, resetForm, errorState
  } = useForm(getInitialFormState(), submitFormCallback, validate);
  const [state, setState] = useState(getInitialState());
  const [fileState, setFileState] = useState({ birthCertificateFileUrl: '', birthCertificateFileName: '' });
  const [removeFileModalState, setRemoveFileModalState] = useState(getInitialRemoveFileModalState());
  const { baseMemberState } = useBaseMemberData();

  const onUploadBirthCertificateClicked = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    setState({ ...state, error: '' });
    if (e.target.files[0]) {
      const file = e.target.files[0];
      const fileType = e.target.files[0].type;

      if (fileType === Constants.FILE_TYPE_PDF || fileType === Constants.FILE_TYPE_DOC ||
        fileType === Constants.FILE_TYPE_DOCX) {
        postMemberFilesBirthCertificate(file);
      }
      else {
        setState({ ...state, error: 'File type not permitted. Please provide a (*.pdf), (*.doc), or (*.docx) file.' });
      }
    }
  };

  const onRemoveBirthCertificateClicked = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    setRemoveFileModalState({ ...getInitialRemoveFileModalState() });
    if (basePersonState.isSaving === true || memberFilesBirthCertificateState.isSaving === true) {
      setState({ ...state, error: 'The last action is still saving. Please try again.' });
    }
    else {
      setFileState({ ...fileState, birthCertificateFileUrl: '', birthCertificateFileName: '' });
      setState({ ...state, error: '' });
      const url = null;
      putBasePerson(basePersonState.objData.personId, editPersonObj(url));
    }
  };

  const onOpenRemoveFileModalClicked = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    setRemoveFileModalState({ ...removeFileModalState, fileName: fileState.birthCertificateFileName, displayPopUp: true });
  };

  const onRemoveFileModalCanceled = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    setRemoveFileModalState({ ...getInitialRemoveFileModalState() });
  };

  const onDownloadUploadedFile = (event) => {
    event.preventDefault();
    const fileUrl = GET_MEMBER_FILES_BIRTH_CERTIFICATE_PATH + fileState.birthCertificateFileUrl
    PersonHttpHelper(fileUrl, 'GET', null, false, true)
      .catch((e) => {
        const message = e && e.message ? e.message : NO_DATA_MESSAGE;
        setState({
          ...state,
          message: message
        });
      });
  };

  function getGenderIdentityArray() {
    const createGenderIdentityArray = genderIdentityState?.arrayData?.map((gender) => {
      return {
        name: gender.typeName,
        id: gender.genderIdentityTypeId
      };
    });

    setRadioButtonListState({
      ...radioButtonListState,
      radiobuttonlistGenderIdentity: createGenderIdentityArray
    });
    return createGenderIdentityArray;
  }

  useEffect(() => {
    if (radioButtonListState.radiobuttonlistGenderIdentity?.length === 0) {
      getGenderIdentityTypes();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (genderIdentityState?.arrayData?.length > 0) {
      getGenderIdentityArray();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [genderIdentityState.arrayData]);

  useEffect(() => {
    //Populate formState with data from the getPerson API call
    if (basePersonState.isObjLoaded === true && basePersonState.isSaving === false
      && genderIdentityState.isArrayLoaded === true && baseMemberState.isObjLoaded === true) {
      const birthDateObj = evaluateBirthDate(formatDate(basePersonState.objData.birthDate));
      const showEmail = birthDateObj.isJunior === true || birthDateObj.isAdult === true;

      let suffixIndex = undefined;
      for (let i = 0; i < suffixOptions.length; i++) {
        if (basePersonState.objData.usasPerson && (basePersonState.objData.usasPerson.suffix === suffixOptions[i].value)) {
          suffixIndex = i;
          break;
        }
      }
      setState({
        ...state,
        showEmail,
        showUploadBirthCertificate: basePersonState.objData.usasPerson ? !basePersonState.objData.usasPerson.birthDateConfirmed : true
      });
      setFileState({
        ...state,
        birthCertificateFileUrl: basePersonState.objData.usasPerson && basePersonState.objData.usasPerson?.birthCertificateUrl ?
          basePersonState.objData.usasPerson?.birthCertificateUrl : '',
        birthCertificateFileName: basePersonState.objData.usasPerson && basePersonState.objData.usasPerson?.birthCertificateUrl ?
          getFileNameFromUrl(basePersonState.objData.usasPerson?.birthCertificateUrl) : '',
      });

      let targetGenderIdentity = genderIdentityState.arrayData.find(x => x.typeName === basePersonState.objData.genderIdentityType?.typeName);

      if ((targetGenderIdentity ?? true) === true) {
        targetGenderIdentity = genderIdentityState.arrayData.find(x => x.typeName === DEFAULT_GENDER_IDENTITY);
      }

      setFormData({
        ...formState,
        firstName: basePersonState.objData.firstName,
        preferredName: basePersonState.objData.preferredName,
        middleName: basePersonState.objData.middleName,
        lastName: basePersonState.objData.lastName,
        maidenName: basePersonState.objData.usasPerson && basePersonState.objData.usasPerson.maidenName ? basePersonState.objData.usasPerson.maidenName : '',
        suffixId: suffixIndex !== undefined ? suffixOptions[suffixIndex].id : suffixOptions[0].id,
        suffixName: suffixIndex !== undefined ? suffixOptions[suffixIndex].name : suffixOptions[0].name,
        email: showEmail === true ? basePersonState.objData.email : '',
        isUsCitizen: basePersonState.objData.usasPerson?.isUsCitizen === true || basePersonState.objData.usasPerson?.isUsCitizen === false ?
          basePersonState.objData.usasPerson?.isUsCitizen : '',
        birthDate: formatDate(basePersonState.objData.birthDate),
        competitionGender: basePersonState.objData.competitionGenderType?.typeName || '',
        isEmailRequired: baseMemberState.objData.isEmailRequired,
        genderIdentity: targetGenderIdentity?.typeName || '',
        genderIdentityTypeId: targetGenderIdentity?.genderIdentityTypeId || ''
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [basePersonState, genderIdentityState, baseMemberState]);

  useEffect(() => {
    if (memberFilesBirthCertificateState.isSaving === false && memberFilesBirthCertificateState.isSaved === true &&
      Object.keys(memberFilesBirthCertificateState.objData).length > 0) {
      setFileState({ ...fileState, templateFileName: memberFilesBirthCertificateState.objData.uploadUrl, templateFileNameShortened: getFileNameFromSavedUploadUrl() });
      putBasePerson(basePersonState.objData.personId, editPersonObj(memberFilesBirthCertificateState.objData.uploadUrl));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [memberFilesBirthCertificateState]);

  useEffect(() => { // base & general share self contact values
    if (basePersonState.isSaved === true) {
      getPersonGeneral(basePersonState.objData.personId);
      confirmBasePersonSave();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [basePersonState.isSaved]);

  function submitFormCallback(formState) {
    if (basePersonState.isSaving === false) {
      putBasePerson(basePersonState.objData.personId, createPutPersonObject());
    }
  }

  function createPutPersonObject() {
    let suffix = undefined;
    for (const suffixOption of suffixOptions) {
      if (formState.suffixId === suffixOption.id) {
        suffix = suffixOption.value;
        break;
      }
    }

    return {
      ...basePersonState.objData,
      firstName: formState.firstName.trim(),
      preferredName: formState.preferredName.trim(),
      middleName: formState.middleName.trim(),
      lastName: formState.lastName.trim(),
      email: formState.email.trim(),
      genderIdentityTypeId: formState.genderIdentityTypeId || null,
      genderIdentityType: undefined,
      usasPerson: basePersonState.objData.usasPerson
        ? {
          ...basePersonState.objData.usasPerson,
          maidenName: formState.maidenName.trim() !== '' ? formState.maidenName.trim() : null,
          suffix: suffix !== undefined ? suffix : null,
          isUsCitizen: formState.isUsCitizen
        }
        : {
          ...createUsasPersonIfUndefinedOrNull(basePersonState.objData.personId),
          maidenName: formState.maidenName.trim() !== '' ? formState.maidenName.trim() : null,
          suffix: suffix !== undefined ? suffix : null,
          isUsCitizen: formState.isUsCitizen
        }
    };
  }

  function editPersonObj(url) {
    let personCopy = JSON.parse(JSON.stringify(basePersonState.objData));

    if (personCopy.usasPerson) {
      personCopy.usasPerson = {
        ...basePersonState.objData.usasPerson,
        birthCertificateUrl: url
      };
    }
    else {
      personCopy.usasPerson = {
        ...createUsasPersonIfUndefinedOrNull(basePersonState.objData.personId),
        birthCertificateUrl: url
      };
    }

    return personCopy;
  }

  function getInitialFormState() {
    return {
      firstName: '',
      preferredName: '',
      middleName: '',
      lastName: '',
      maidenName: '',
      suffixId: Constants.DEFAULT_ID,
      suffixName: '',
      email: '',
      isUsCitizen: '',
      birthDate: Constants.BLANK_DATE_STRING,
      competitionGender: '',
      genderIdentity: '',
      genderIdentityTypeId: '',
      isEmailRequired: true
    };
  }

  function getInitialState() {
    return {
      showEmail: false,
      showUploadBirthCertificate: false,
      error: ''
    };
  }

  function getInitialRemoveFileModalState() {
    return {
      displayPopUp: false,
      fileName: '',
      modalTitle: 'Remove Uploaded File?'
    };
  }

  return {
    state,
    fileState,
    removeFileModalState,
    radioButtonListState,
    basePersonState,
    personGeneralState,
    memberFilesBirthCertificateState,
    genderIdentityState,
    formState,
    errorState,
    onFormValueChanged: updateFormState,
    onValueTextPairChanged,
    handleSubmit,
    resetForm,
    onUploadBirthCertificateClicked,
    onRemoveBirthCertificateClicked,
    onOpenRemoveFileModalClicked,
    onRemoveFileModalCanceled,
    onDownloadUploadedFile,
    suffixOptions
  };
};

export default useMemberInfo;