import React, { Fragment } from 'react';

import useMemberAdminManageTransfer from './UseMemberAdminManageTransfer';

import Headings from '../../../../common/components/headings/Headings';
import PopUpModal from '../../../../common/components/dialogs/PopUpModal';
import ReadOnly from '../../../../common/components/readOnly/ReadOnly';
import DatePicker from '../../../../common/components/datepickers/DatePicker';
import PrimaryButton from '../../../../common/components/buttons/PrimaryButton';
import SecondaryButton from '../../../../common/components/buttons/SecondaryButton';
import DeleteUnattachedTransferModal from './DeleteUnattachedTransferModal';

const MemberAdminManageTransfer = () => {
  const {
    title,
    Constants,
    basePersonState,
    formState,
    onFormValueChanged,
    memberClubTransferState,
    onUpdateClubTransferReattachDate,
    removePersonClubTransfer,
    errorMessage,
    modalState,
    onModalCanceled,
    onOpenModal,
    isTextView
  } = useMemberAdminManageTransfer();

  const clubFrom = `${formState.clubFromName} (${formState.clubFromCode}) `;
  const clubTo = `${formState.clubToName} (${formState.clubToCode}) `;

  if (Object?.keys(memberClubTransferState?.objData)?.length === 0 || isTextView === true) {
    return (
      <Fragment>
        There is no record of any transfer for this member
      </Fragment>
    );
  } else {
    return (
      <Fragment>
        <div className="row usas-extra-top-margin">
          <div className="col-xs-12">
            <Headings.H3>{title}</Headings.H3>
          </div>
        </div>
        <div className="row usas-extra-top-margin usas-extra-bottom-margin">
          <div className="col-xs-12 col-md-4">
            <ReadOnly
              label="Club Transfer From"
              name="clubFrom"
              value={clubFrom} />
          </div>
          <div className="col-xs-12 col-md-4">
            <ReadOnly
              label="Club Transfer To"
              name="clubTo"
              value={clubTo} />
          </div>
          <div className="col-xs-12 col-md-4">
            <DatePicker
              label="Re-Attach Date"
              name="reAttachDate"
              value={formState.reAttachDate}
              error={errorMessage}
              message={errorMessage}
              onChange={(value) => { onFormValueChanged('reAttachDate', value); }}
              countOfYears={150}
              startYearOffset={-149} />
          </div>
        </div>
        <div className="row usas-extra-top-margin usas-extra-bottom-margin">
          <div className="col-xs-12 usas-extra-top-margin">
            <PrimaryButton type="button" onClick={onUpdateClubTransferReattachDate}>Save</PrimaryButton>&nbsp;
            <SecondaryButton type="button" onClick={onOpenModal}>Delete Unattached</SecondaryButton>
          </div>
        </div>
        <PopUpModal
          widthPct={90}
          maxWidth={720}
          title={modalState?.modalTitle}
          displayPopUp={modalState.displayPopUp}
          onModalCanceled={onModalCanceled}>
          <DeleteUnattachedTransferModal modalState={modalState} />
          <div className="row">
            <div className="col-xs-12 usas-extra-top-margin">
              <SecondaryButton type="button" onClick={onModalCanceled}>Cancel</SecondaryButton>&nbsp;&nbsp;
              <PrimaryButton onClick={removePersonClubTransfer}>Yes, Delete Unattached</PrimaryButton>
            </div>
          </div>
        </PopUpModal>
        <PopUpModal
          widthPct={50}
          maxWidth={250}
          title={Constants.LOADING_MSG}
          displayPopUp={basePersonState.isObjLoading || memberClubTransferState.isArrayLoading} />
        <PopUpModal
          widthPct={50}
          maxWidth={250}
          title={Constants.SAVING_MSG}
          displayPopUp={memberClubTransferState.isSaving} />
      </Fragment >
    );
  }
};

export default MemberAdminManageTransfer;