import { useEffect } from 'react';
import { useLocation, useNavigate } from '../../../../../common/wrappers/ReactRouterDom';

import useOtsMeetData from '../../../../state/officialsTrackingSystemMeet/UseOtsMeetData';
import useOtsMeetSessionsData from '../../../../state/officialsTrackingSystemMeetSessions/UseOtsMeetSessionsData';
import useNavRoutes from '../../../../../common/state/security/UseNavRoutes';

import Constants from '../../../../../common/utils/Constants';

const useOtsMeetSessions = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { otsMeetHeaderState } = useOtsMeetData();
  const { otsMeetSessionsState, getMeetSessions } = useOtsMeetSessionsData();
  const { navRoutes } = useNavRoutes();

  const onEditSession = (e, session) => {
    if (e?.preventDefault) {
      e.preventDefault();
    }

    navigate(navRoutes.OTS_MEET_SESSIONS_DETAIL?.route, { state: { meetSessionId: session.meetSessionId } });
  }

  useEffect(() => {
    if (location.state && location.state?.tryGet === true) {
      getMeetSessions(otsMeetHeaderState.objData?.meetId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (otsMeetSessionsState.isArrayLoading !== true && otsMeetSessionsState.isArrayLoaded !== true) {
      getMeetSessions(otsMeetHeaderState.objData?.meetId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [otsMeetHeaderState, otsMeetSessionsState])

  return {
    otsMeetHeaderState,
    otsMeetSessionsState,
    isLoading: otsMeetSessionsState.isArrayLoading,
    isSaving: otsMeetSessionsState.isSaving,
    Constants,
    navRoutes,
    onEditSession
  };
}

export default useOtsMeetSessions;