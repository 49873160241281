import Constants from '../../../common/utils/Constants';
import { PersonHttpHelper, NO_DATA_MESSAGE } from '../../../common/utils/HttpHelper';

export const INITIAL_STATE = {
  isLoading: false,
  isLoaded: false,
  isSaving: false,
  data: [],
  dataAsOptionsWithNames: [],
  message: ''
};

export const defaultContactRelationshipTypeState = {
  ContactRelationshipTypeId: Constants.DEFAULT_ID,
  ContactRelationshipTypeName: ''
};

const getContactRelationshipTypes = (state, setState) => {
  if (state.isLoading !== true && state.isLoaded !== true && !state.message) {
    let newState = {
      ...state,
      isLoading: true,
      isLoaded: false,
      data: [],
      dataAsOptionsWithNames: [],
      message: 'Loading...'
    };
    setState(newState);

    const url = `/ContactRelationshipType`;
    PersonHttpHelper(url, 'GET')
      .then((data) => {
        if (!data) {
          throw new Error(NO_DATA_MESSAGE);
        } else {
          let index = data.findIndex(e => e.contactRelationshipTypeId === 41);
          data.splice(index, 1);
          data.unshift({ contactRelationshipTypeId: Constants.DEFAULT_ID, typeName: '--' });
          newState = {
            ...newState,
            isLoading: false,
            isLoaded: true,
            data: data,
            dataAsOptionsWithNames: data.map((relationshipType) => { return { id: relationshipType.contactRelationshipTypeId, name: relationshipType.typeName } }),
            message: '',
          };
          setState(newState);
        }
      })
      .catch((e) => {
        const message = e && e.message ? e.message : NO_DATA_MESSAGE;
        setState({
          ...newState,
          isLoading: false,
          isLoaded: false,
          message: message
        });
      });
  };
};

const ContactRelationshipTypeData = { INITIAL_STATE, getContactRelationshipTypes };

export default ContactRelationshipTypeData;