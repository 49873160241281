import { Fragment } from 'react';

import Checkbox from '../../../../../../common/components/checkboxes/Checkbox';
import ActionIntraPageButton from '../../../../../../common/components/buttons/ActionIntraPageButton';

import { formatDate } from '../../../../../../common/utils/DateFunctions';

import global from '../../../../../../common/components/GlobalStyle.module.css';

const SmallGridRowIcons = ({ official, foundIndex, onAddOrRemoveLscCertficationForBulkRecertify }) => (
  <Fragment>
    <span className='pull-right'>
      <Checkbox
        name="personId"
        checked={foundIndex >= 0 ? true : false}
        onChange={(checked) => { onAddOrRemoveLscCertficationForBulkRecertify(checked, official, official?.personId) }} />
    </span>
  </Fragment>
);

const SmallGridRow = ({ official, foundIndex, onAddOrRemoveLscCertficationForBulkRecertify, onOfficialHistoryReportLoadClick }) => (
  <Fragment>
    <div className={global.SmallTableRow}>
      <div className={global.SmallTableRowHead}>
        <span className='hidden-xs'>{official?.firstName} {official?.middleName} {official?.lastName}</span>&nbsp;
        <SmallGridRowIcons
          official={official}
          foundIndex={foundIndex}
          onAddOrRemoveLscCertficationForBulkRecertify={onAddOrRemoveLscCertficationForBulkRecertify} />
        <div style={{ maxWidth: '300px', marginTop: '10px' }}>  <ActionIntraPageButton onClick={() => onOfficialHistoryReportLoadClick(official)}>Open Official&apos;s History Report</ActionIntraPageButton></div>
      </div>
      <div className={global.SmallTableRowInfo}>
        <div className='row'>
          <div className={['col-xs-6 col-sm-3 visible-xs', global.SmallTableRowLabels].join(' ')}>
            Name
          </div>
          <div className={['col-xs-6 col-sm-3 visible-xs', global.SmallTableRowData].join(' ')}>
            {official?.firstName} {official?.middleName} {official?.lastName}
          </div>
          <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>
            Position
          </div>
          <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>
            {official?.positionTypeName} ({official?.positionAbbreviation})
          </div>
          <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>
            Certification Level
          </div>
          <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>
            {official?.levelName}
          </div>
          <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>
            Sessions Worked
          </div>
          <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>
            {official?.sessionsWorked >= 0 ? official?.sessionsWorked : <span>&nbsp;</span>}
          </div>
          <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>
            Expiration Date
          </div>
          <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>
            {formatDate(official?.expirationDate)}
          </div>
        </div>
      </div>
    </div>
  </Fragment>
);


const OtsOfficialLscBulkRecertifySmallGrid = ({ state, selectedOfficials, onAddOrRemoveLscCertficationForBulkRecertify, onOfficialHistoryReportLoadClick }) => (
  <div className={[global.SmallTable, 'hidden-md hidden-lg'].join(' ')}>
    {Array.isArray(state?.arrayData) && state?.arrayData?.length > 0
      ? state?.arrayData?.map((official, i) => {
        const foundIndex = selectedOfficials.findIndex(x => x.personId === official.personId);
        return (
          <SmallGridRow
            key={i}
            official={official}
            foundIndex={foundIndex}
            onAddOrRemoveLscCertficationForBulkRecertify={onAddOrRemoveLscCertficationForBulkRecertify}
            onOfficialHistoryReportLoadClick={onOfficialHistoryReportLoadClick} />)
      })
      : (
        <div>
          {state?.isArrayLoading
            ? (
              <div className={global.SmallTableRow}>
                <div className={global.SmallTableRowHead}>&nbsp;</div>
                <div className={global.SmallTableRowInfo}><div className={global.SmallTableRowLabels}>Loading...</div></div>
              </div>
            ) : state?.isArrayLoaded === true && state?.arrayData?.length === 0
              ? (
                <div className={global.SmallTableRow}>
                  <div className={global.SmallTableRowHead}>&nbsp;</div>
                  <div className={global.SmallTableRowInfo}><div className={global.SmallTableRowLabels}>No Results</div></div>
                </div>
              )
              :
              (
                <div className={global.SmallTableRow}>
                  <div className={global.SmallTableRowHead}>&nbsp;</div>
                  <div className={global.SmallTableRowInfo}><div className={global.SmallTableRowLabels}>Please select a Position Worked and a Certification Level and click 'Filter' to generate results</div></div>
                </div>
              )
          }
        </div>
      )
    }
  </div>
);

export default OtsOfficialLscBulkRecertifySmallGrid;