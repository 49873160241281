import { Fragment } from 'react';

import useRulesList from './UseRulesList';

import CertificationHeader from '../CertificationHeader';

import Headings from '../../../../../common/components/headings/Headings';
import PrimaryButton from '../../../../../common/components/buttons/PrimaryButton';
import SecondaryButton from '../../../../../common/components/buttons/SecondaryButton';
import PopUpModal from '../../../../../common/components/dialogs/PopUpModal';

import style from './RulesList.module.css';

const Rule = ({ rule }) => {
  return (
    <li>
      {rule.ruleDescription}
      {rule.ruleNotes?.length > 0 && (
        <ul className={style.InnerUL}>
          {rule.ruleNotes.map((ruleNote, i) => <li key={i}>{ruleNote.ruleNote}</li>)}
        </ul>
      )}
    </li>
  );
};

const RulesList = ({ positionCertificationProgressions, onApply, onBackClicked, rulePageNumber, onNextRuleClicked, onBackToPreviousRuleClicked,
  modalState, buttonClickHandlers, officialName, memberId, formState }) => {
  const { rules } = useRulesList(positionCertificationProgressions);
  return (
    <>
      {rules && rulePageNumber && rules[rulePageNumber - 1] &&
        <div key={rulePageNumber}>
          <div className="row usas-extra-bottom-margin" >
            <div className="col-xs-12">
              <CertificationHeader positionCertificationProgression={rules[rulePageNumber - 1]} showApplicationStatus={false} />
            </div>
          </div>
          <Fragment>
            {rules[rulePageNumber - 1]?.newRequirementTypes.map((item, j) => (
              <div className="row usas-extra-bottom-margin" key={j}>
                <div className="col-xs-12">
                  <Headings.H5>{item.requirementType}</Headings.H5>
                  <ul className={style.OuterUL}>
                    {item.rules.map((rule, a) => <Rule key={a} rule={rule} />)}
                  </ul>
                </div>
              </div>))}
          </Fragment>
        </div>
      }
      <div className='row'>
        <div className="col-xs-12 usas-extra-top-margin">
          {rules.length > 0 && rulePageNumber < rules.length && <><PrimaryButton type="button" onClick={onNextRuleClicked}>Next</PrimaryButton>&nbsp;</>}
          {rules.length > 0 && rulePageNumber === rules.length && <><PrimaryButton type="button" onClick={onApply}>Apply</PrimaryButton>&nbsp;</>}
          {rulePageNumber === 1 && <SecondaryButton type="button" onClick={onBackClicked}>Back</SecondaryButton>}
          {rulePageNumber > 1 && <SecondaryButton type="button" onClick={onBackToPreviousRuleClicked}>Back</SecondaryButton>}
        </div>
      </div>
      <PopUpModal
        widthPct={90}
        maxWidth={750}
        title={modalState.modalTitle}
        displayPopUp={modalState.displayPopUp}
        onModalCanceled={buttonClickHandlers.onAddRecertPromoteSuccessful}>
        <div className="row usas-extra-bottom-margin">
          <div className="col-xs-12">
            <p>Thank you for Applying for Certification as an Official.</p>
            <p><b>Official Name:</b> {officialName || ''}</p>
            <p><b>Member ID:</b> {memberId || ''}</p>
            <p><b>Selected Position(s):</b></p>
            <ul className={style.OuterUL}>{formState.positionCertificationProgressionIds?.map((x, i) => { return (<li key={i}>{x.name || ''}</li>) })}</ul>
          </div>
          <div className="col-xs-12 usas-extra-top-margin">
            <p><b>Application Review can take up to two weeks.</b></p>
          </div>
          <div className="col-xs-12 usas-extra-top-margin">
            <SecondaryButton type="button" onClick={buttonClickHandlers.onAddRecertPromoteSuccessful}>Close</SecondaryButton>
          </div>
        </div>
      </PopUpModal>
    </>
  );
};

export default RulesList;