import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { Navigate } from '../../../../common/wrappers/ReactRouterDom';

import OfficialsTrackingSystemMeetContextView from './OfficialsTrackingSystemMeetContextView';

import OtsMeetContextProvider from '../../../state/officialsTrackingSystemMeet/OtsMeetContextProvider';
import OtsMeetFiltersContextProvider from '../../../state/officialsTrackingSystemMeet/OtsMeetFiltersContextProvider';
import OtsMeetHeaderContextProvider from '../../../state/officialsTrackingSystemMeet/OtsMeetHeaderContextProvider';
import SearchOtsMeetContextProvider from '../../../state/searchOfficialsTrackingSystemMeet/SearchOtsMeetContextProvider';
import OtsMeetSessionsContextProvider from '../../../state/officialsTrackingSystemMeetSessions/OtsMeetSessionsContextProvider';
import MeetOfficialSessionContextProvider from '../../../state/meetOfficialSession/MeetOfficialSessionContextProvider';
import OfficialPositionContextProvider from '../../../state/officialPosition/OfficialPositionContextProvider';
import OfficialPositionTypeContextProvider from '../../../state/officialPositionType/OfficialPositionTypeContextProvider';
import OfficialsTrackingSystemOfficialSelectionContextProvider from '../../../state/officialsTrackingSystemOfficialSelection/OfficialsTrackingSystemOfficialSelectionContextProvider';
import OtsMeetOfficialsContextProvider from '../../../state/officialsTrackingSystemMeetOfficials/OtsMeetOfficialsContextProvider';
import MeetEvaluationContextProvider from '../../../state/otsMeetEvaluations/MeetEvaluationsContextProvider';
import EvaluationLevelContextProvider from '../../../state/evaluationLevel/EvaluationLevelContextProvider';
import OfficialEvaluationContextProvider from '../../../state/otsOfficialEvaluations/OfficialEvaluationsContextProvider';
import OfficialSelectionForEvaluationContextProvider from '../../../state/otsOfficialSelectionForEvaluations/OfficialSelectionForEvaluationsContextProvider';
import MeetEvaluationPersonContextProvider from '../../../state/otsMeetEvaluationsPerson/MeetEvaluationPersonContextProvider';
import MeetOfficialRoleTypeContextProvider from '../../../state/meetOfficialRoleType/MeetOfficialRoleTypeContextProvider';
import OtsMeetStaffContextProvider from '../../../state/otsMeetStaff/OtsMeetStaffContextProvider';
import OtsMeetOfficialContextProvider from '../../../state/otsMeetOfficial/OtsMeetOfficialContextProvider';

import MeetClassificationContextProvider from '../../../../common/state/meet/meetClassification/MeetClassificationContextProvider';
import MeetSessionsContextProvider from '../../../../common/state/meetSessions/MeetSessionsContextProvider';
import ReportPeriodSeasonContextProvider from '../../../../common/state/reportPeriodSeason/ReportPeriodSeasonContextProvider';
import SelectOrgUnitContextProvider from '../../../../common/state/selectOrgUnit/SelectOrgUnitContextProvider';
import PersonOrgRoleContextProvider from '../../../../common/state/personOrgRole/PersonOrgRoleContextProvider';
import ZoneContextProvider from '../../../../common/state/zone/ZoneContextProvider';

import OtsMeetSearch from './otsMeetSearch/OtsMeetSearch';
import MyLscOfficialsTrackingSystemMeet from './MyLscOfficialsTrackingSystemMeet';
import MyOtsMeets from './myOtsMeets/MyOtsMeets';
import MyOtsEvaluations from './myOtsEvaluations/MyOtsEvaluations';
import OtsMeetInfo from './otsMeetInfo/OtsMeetInfo';
import OtsMeetSessions from './otsMeetSessions/OtsMeetSessions';
import OtsMeetSessionsDetail from './otsMeetSessions/OtsMeetSessionsDetail';
import OtsMeetStaff from './otsMeetStaff/OtsMeetStaff';
import OtsMeetStaffDetail from './otsMeetStaff/OtsMeetStaffDetail';
import OtsMeetOfficials from './otsMeetOfficials/OtsMeetOfficials';
import OtsMeetOfficialsOfficialSelection from './otsMeetOfficials/OtsMeetOfficialsOfficialSelection';
import OtsMeetOfficialsOfficial from './otsMeetOfficials/OtsMeetOfficialsOfficial';
import OtsMeetOfficialsOfficialDetail from './otsMeetOfficials/OtsMeetOfficialsOfficialDetail';
import OtsMeetReporting from './otsMeetReporting/OtsMeetReporting';
import OtsMeetEvaluators from './otsMeetEvaluators/OtsMeetEvaluators';
import OtsMeetEvaluationsWrite from './otsMeetEvaluations/OtsMeetEvaluationsWrite';
import OtsMeetEvaluationsReadOnly from './otsMeetEvaluations/OtsMeetEvaluationsReadOnly';
import OtsMeetEvaluationsDetail from './otsMeetEvaluations/OtsMeetEvaluationsDetail';
import OtsMeetEvaluationsOverride from './otsMeetEvaluations/OtsMeetEvaluationsOverride';
import OtsPositionWorkedEvaluationLevelEvaluatorNameContextProvider from '../../../state/otsPositionWorkedEvaluationLevelEvaluatorName/OtsPositionWorkedEvaluationLevelEvaluatorNameContextProvider';
import OtsMeetEvaluationForm from './otsMeetEvaluations/OtsMeetEvaluationForm';
import MyOtsMeetEvaluationForm from './myOtsEvaluations/MyOtsMeetEvaluationForm';

import SecuredRouteElement from '../../../../common/components/security/SecuredRouteElement';
import LoadingModal from '../../../../common/components/dialogs/LoadingModal';

import useNavRoutes from '../../../../common/state/security/UseNavRoutes';

const ContextRoutes = ({ navRoutes }) => (
  <OtsMeetOfficialContextProvider>
    <OtsPositionWorkedEvaluationLevelEvaluatorNameContextProvider>
      <OfficialSelectionForEvaluationContextProvider>
        <MeetEvaluationContextProvider>
          <MeetOfficialSessionContextProvider>
            <OtsMeetStaffContextProvider>
              <MeetSessionsContextProvider>
                <OtsMeetSessionsContextProvider>
                  <OfficialsTrackingSystemOfficialSelectionContextProvider>
                    <OfficialsTrackingSystemMeetContextView>
                      <Routes>
                        <Route path={navRoutes['OTS_MEET_INFO']?.path}
                          element={
                            <SecuredRouteElement
                              navRoute={navRoutes['OTS_MEET_INFO']}
                              editElement={<OtsMeetInfo />} />
                          }
                        />
                        <Route path={navRoutes['OTS_MEET_SESSIONS']?.path}
                          element={
                            <SecuredRouteElement
                              navRoute={navRoutes['OTS_MEET_SESSIONS']}
                              editElement={<OtsMeetSessions />} />
                          }
                        />
                        <Route path={navRoutes['OTS_MEET_SESSIONS_DETAIL']?.path}
                          element={
                            <SecuredRouteElement
                              navRoute={navRoutes['OTS_MEET_SESSIONS_DETAIL']}
                              editElement={<OtsMeetSessionsDetail />} />
                          }
                        />
                        <Route path={navRoutes['OTS_MEET_STAFF']?.path}
                          element={
                            <SecuredRouteElement
                              navRoute={navRoutes['OTS_MEET_STAFF']}
                              editElement={<OtsMeetStaff />} />
                          }
                        />
                        <Route path={navRoutes['OTS_MEET_STAFF_DETAIL']?.path}
                          element={
                            <SecuredRouteElement
                              navRoute={navRoutes['OTS_MEET_STAFF_DETAIL']}
                              editElement={<OtsMeetStaffDetail />} />
                          }
                        />
                        <Route path={navRoutes['OTS_MEET_OFFICIALS']?.path}
                          element={
                            <SecuredRouteElement
                              navRoute={navRoutes['OTS_MEET_OFFICIALS']}
                              editElement={<OtsMeetOfficials />} />
                          }
                        />
                        <Route path={navRoutes['OTS_MEET_OFFICIALS_SELECTION']?.path}
                          element={
                            <SecuredRouteElement
                              navRoute={navRoutes['OTS_MEET_OFFICIALS_SELECTION']}
                              editElement={<OtsMeetOfficialsOfficialSelection />} />
                          }
                        />
                        <Route path={navRoutes['OTS_MEET_OFFICIALS_OFFICIAL']?.path}
                          element={
                            <SecuredRouteElement
                              navRoute={navRoutes['OTS_MEET_OFFICIALS_OFFICIAL']}
                              editElement={<OtsMeetOfficialsOfficial />} />
                          }
                        />
                        <Route path={navRoutes['OTS_MEET_OFFICIALS_OFFICIAL_DETAIL']?.path}
                          element={
                            <SecuredRouteElement
                              navRoute={navRoutes['OTS_MEET_OFFICIALS_OFFICIAL_DETAIL']}
                              editElement={<OtsMeetOfficialsOfficialDetail />} />
                          }
                        />
                        <Route path={navRoutes['OTS_MEET_REPORTING']?.path}
                          element={
                            <SecuredRouteElement
                              navRoute={navRoutes['OTS_MEET_REPORTING']}
                              editElement={<OtsMeetReporting />} />
                          }
                        />
                        <Route path={navRoutes['OTS_MEET_EVALUATORS']?.path}
                          element={
                            <SecuredRouteElement
                              navRoute={navRoutes['OTS_MEET_EVALUATORS']}
                              editElement={<OtsMeetEvaluators />} />
                          }
                        />
                        <Route path={navRoutes['OTS_MEET_EVALUATIONS']?.path}
                          element={
                            <SecuredRouteElement
                              navRoute={navRoutes['OTS_MEET_EVALUATIONS']}
                              editElement={<OtsMeetEvaluationsWrite />}
                              readOnlyElement={<OtsMeetEvaluationsReadOnly />} />
                          }
                        />
                        <Route path={navRoutes['OTS_MEET_EVALUATIONS_DETAIL']?.path}
                          element={
                            <SecuredRouteElement
                              navRoute={navRoutes['OTS_MEET_EVALUATIONS_DETAIL']}
                              editElement={<OtsMeetEvaluationsDetail />} />
                          }
                        />
                        <Route path={navRoutes['OTS_MEET_EVALUATIONS_OVERRIDE']?.path}
                          element={
                            <SecuredRouteElement
                              navRoute={navRoutes['OTS_MEET_EVALUATIONS_OVERRIDE']}
                              editElement={<OtsMeetEvaluationsOverride />} />
                          }
                        />
                        <Route path={navRoutes['OTS_MEET_EVALUATIONS_FORM']?.path}
                          element={
                            <SecuredRouteElement
                              navRoute={navRoutes['OTS_MEET_EVALUATIONS_FORM']}
                              editElement={<OtsMeetEvaluationForm />} />
                          }
                        />
                      </Routes>
                    </OfficialsTrackingSystemMeetContextView>
                  </OfficialsTrackingSystemOfficialSelectionContextProvider>
                </OtsMeetSessionsContextProvider>
              </MeetSessionsContextProvider>
            </OtsMeetStaffContextProvider>
          </MeetOfficialSessionContextProvider>
        </MeetEvaluationContextProvider>
      </OfficialSelectionForEvaluationContextProvider>
    </OtsPositionWorkedEvaluationLevelEvaluatorNameContextProvider>
  </OtsMeetOfficialContextProvider>
);

const OfficialsTrackingSystemMeetRoutes = () => {
  const { navRoutes, isReadyToRoute } = useNavRoutes();

  if (isReadyToRoute !== true) {
    return <LoadingModal />;
  }

  return (
    <Routes>
      <Route path={navRoutes['OTS_MEET_SEARCH']?.path}
        element={
          <SecuredRouteElement
            navRoute={navRoutes['OTS_MEET_SEARCH']}
            editElement={<OtsMeetSearch />} />
        }
      />
      <Route path={navRoutes['OTS_MEET_MY_LSC_OTS_MEETS']?.path}
        element={
          <SecuredRouteElement
            navRoute={navRoutes['OTS_MEET_MY_LSC_OTS_MEETS']}
            editElement={<MyLscOfficialsTrackingSystemMeet />} />
        }
      />
      <Route path={navRoutes['OTS_MEET_MY_OTS_MEETS']?.path}
        element={
          <SecuredRouteElement
            navRoute={navRoutes['OTS_MEET_MY_OTS_MEETS']}
            editElement={<MyOtsMeets />} />
        }
      />
      <Route path={navRoutes['OTS_MEET_MY_OTS_EVALUATIONS']?.path}
        element={
          <SecuredRouteElement
            navRoute={navRoutes['OTS_MEET_MY_OTS_EVALUATIONS']}
            editElement={<MyOtsEvaluations />} />
        }
      />
      <Route path={navRoutes['OTS_MEET_MY_OTS_EVALUATIONS_FORM']?.path}
        element={
          <SecuredRouteElement
            navRoute={navRoutes['OTS_MEET_MY_OTS_EVALUATIONS_FORM']}
            editElement={<MyOtsMeetEvaluationForm />} />
        }
      />

      <Route path={'/*'} element={<ContextRoutes navRoutes={navRoutes} />} />
      <Route path={'/'} element={<Navigate to={navRoutes['OTS_MEET_SEARCH']?.route} replace />} />
    </Routes>
  );
}

const OfficialsTrackingSystemMeet = () => (
  <ZoneContextProvider>
    <ReportPeriodSeasonContextProvider>
      <OfficialPositionContextProvider>
        <OfficialPositionTypeContextProvider>
          <MeetOfficialRoleTypeContextProvider>
            <EvaluationLevelContextProvider>
              <MeetClassificationContextProvider>
                <SearchOtsMeetContextProvider>
                  <OtsMeetHeaderContextProvider>
                    <OtsMeetFiltersContextProvider>
                      <OtsMeetContextProvider>
                        <MeetEvaluationPersonContextProvider>
                          <OtsMeetOfficialsContextProvider>
                            <OfficialEvaluationContextProvider>
                              <SelectOrgUnitContextProvider>
                                <PersonOrgRoleContextProvider>
                                  <OfficialsTrackingSystemMeetRoutes />
                                </PersonOrgRoleContextProvider>
                              </SelectOrgUnitContextProvider>
                            </OfficialEvaluationContextProvider>
                          </OtsMeetOfficialsContextProvider>
                        </MeetEvaluationPersonContextProvider>
                      </OtsMeetContextProvider>
                    </OtsMeetFiltersContextProvider>
                  </OtsMeetHeaderContextProvider>
                </SearchOtsMeetContextProvider >
              </MeetClassificationContextProvider >
            </EvaluationLevelContextProvider>
          </MeetOfficialRoleTypeContextProvider>
        </OfficialPositionTypeContextProvider>
      </OfficialPositionContextProvider>
    </ReportPeriodSeasonContextProvider>
  </ZoneContextProvider>
);

export default OfficialsTrackingSystemMeet;