import { useContext, useEffect } from 'react';
import Constants from '../../../common/utils/Constants';

import { ContactRelationshipTypeStateContext } from './ContactRelationshipTypeContextProvider';

import ContactRelationshipTypeData, { defaultContactRelationshipTypeState } from './ContactRelationshipTypeData';

const useContactRelationshipTypeData = () => {
  const [contactRelationshipTypeState, setContactRelationshipTypeState] = useContext(ContactRelationshipTypeStateContext);

  useEffect(() => {
    if (contactRelationshipTypeState.isLoading !== true && contactRelationshipTypeState.isLoaded !== true) {
      ContactRelationshipTypeData.getContactRelationshipTypes(contactRelationshipTypeState, setContactRelationshipTypeState);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    contactRelationshipTypeState,
    defaultContactRelationshipTypeState: { ...defaultContactRelationshipTypeState, ContactRelationshipTypeId: Constants.DEFAULT_ID, ContactRelationshipTypeName: '' }
  };
};

export default useContactRelationshipTypeData;