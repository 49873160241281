import React, { Fragment } from 'react';

import useOtsSessionsDetail from './UseOtsSessionsDetail';

import ReadOnly from '../../../../../common/components/readOnly/ReadOnly';
import YesNoSwitch from '../../../../../common/components/yesNoSwitch/YesNoSwitch';
import PopUpModal from '../../../../../common/components/dialogs/PopUpModal';

import Constants from '../../../../../common/utils/Constants';

import global from '../../../../../common/components/GlobalStyle.module.css';

const RequiredFieldsReadOnlyOptionalFieldsReadOnlyForm = ({ formState, errorState, onFormValueChangedSetMeetSessionsState }) => (
  <div className='row'>
    <div className='col-xs-12 col-sm-6 col-md-4'>
      <ReadOnly
        label="Session Number"
        name="sessionOrderId"
        value={formState.sessionOrderId} />
    </div>
    <div className='col-xs-12 col-sm-6 col-md-4'>
      <ReadOnly
        label="Session Type"
        name="sessionTypeName"
        value={formState.sessionTypeName} />
    </div>
    <div className='col-xs-12 col-sm-6 col-md-4'>
      <ReadOnly
        label="Session Date"
        name="sessionDate"
        value={formState.sessionDate} />
    </div>
    <div className='col-xs-12 col-sm-6 col-md-4'>
      <ReadOnly
        label='Warm Up Time (12-hour clock)'
        name='warmUpTimeTwelveHourClock'
        value={formState.warmUpTimeTwelveHourClock}
      />
    </div>
    <div className='col-xs-12 col-sm-6 col-md-4'>
      <ReadOnly
        label='Start Time (12-hour clock)'
        name='startTimeTwelveHourClock'
        value={formState.startTimeTwelveHourClock}
      />
    </div>
    <div className='col-xs-12 col-sm-6 col-md-4'>
      <YesNoSwitch
        label="Officials Qualifying Meet (OQM) Session?*"
        name="isOfficialsQualifyingSession"
        checked={formState.isOfficialsQualifyingSession}
        error={errorState.isOfficialsQualifyingSession}
        message={errorState.isOfficialsQualifyingSession}
        onChange={(value) => { onFormValueChangedSetMeetSessionsState('isOfficialsQualifyingSession', value); }} />
    </div>
  </div>
);

const SessionsDetail = () => {
  const {
    meetSessionsState,
    otsMeetSessionsState,
    onFormValueChangedSetMeetSessionsState
  } = useOtsSessionsDetail();

  return (
    <Fragment>
      <form>
        <div className={meetSessionsState?.showOptionalFields === true ? global.DisplayComponent : global.HideComponent}>
          <div className={meetSessionsState?.requiredFieldsReadOnly === true ? global.DisplayComponent : global.HideComponent}>
            <RequiredFieldsReadOnlyOptionalFieldsReadOnlyForm
              formState={meetSessionsState?.formState}
              errorState={meetSessionsState?.errorState}
              onFormValueChangedSetMeetSessionsState={onFormValueChangedSetMeetSessionsState}
            />
          </div>
        </div>
      </form>
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={otsMeetSessionsState.isArrayLoading} />
    </Fragment >
  );
};

export default SessionsDetail;