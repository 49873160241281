import useContactRelationshipTypeData from '../../state/contactRelationship/UseContactRelationshipTypeData';

const useContactRelationshipTypeDropdown = () => {
  const { contactRelationshipTypeState } = useContactRelationshipTypeData();

  return {
    contactRelationshipTypeState
  };
};

export default useContactRelationshipTypeDropdown;