import NarrativeTypeContextProvider from './state/narrativeType/NarrativeTypeContextProvider';
import IneligibleReasonContextProvider from './state/ineligibleReason/IneligibleReasonContextProvider';
import SocialMediaTypeContextProvider from './state/socialMediaType/SocialMediaTypeContextProvider';
import FunFactsTypeContextProvider from './state/funFactsType/FunFactsTypeContextProvider';
import FrequentFlyerTypeContextProvider from './state/frequentFlyerType/FrequentFlyerTypeContextProvider';
import SupportStaffTypeContextProvider from './state/supportStaffType/SupportStaffTypeContextProvider';
import TestingBodyContextProvider from './state/testingBody/TestingBodyContextProvider';
import DrugTestStatusContextProvider from './state/drugTestStatus/DrugTestStatusContextProvider';
import SponsorContextProvider from './state/sponsor/SponsorContextProvider';
import CoachTypeContextProvider from './state/coachType/CoachTypeContextProvider';
import ContactRelationshipTypeContextProvider from './state/contactRelationship/ContactRelationshipTypeContextProvider';

import OrganizationContextProvider from '../common/state/organization/OrganizationContextProvider';
import LscContextProvider from '../common/state/lsc/LscContextProvider';
import OrgUnitContextProvider from '../common/state/orgUnit/OrgUnitContextProvider';
import OrgRoleHierarchyContextProvider from '../common/state/orgRoleHierarchy/OrgRoleHierarchyContextProvider';
import ReportPeriodFlatContextProvider from '../common/state/reportPeriodFlat/ReportPeriodFlatContextProvider';
import EventContextProvider from '../common/state/event/EventContextProvider';
import DisabilityTypeContextProvider from '../common/state/disabilityType/DisabilityTypeContextProvider';
import HispanicOrLatinoTypeContextProvider from '../common/state/hispanicOrLatinoType/HispanicOrLatinoTypeContextProvider';
import EthnicityTypeContextProvider from '../common/state/ethnicityType/EthnicityTypeContextProvider';
import CompetitionGenderTypeContextProvider from '../common/state/competitionGender/CompetitionGenderTypeContextProvider';
import OrgGroupPropertiesContextProvider from '../common/state/orgGroupProperties/OrgGroupPropertiesContextProvider';

const AppStateContextProviders = ({ children }) => {
  return (
    <OrganizationContextProvider>
      <LscContextProvider>
        <OrgUnitContextProvider>
          <OrgRoleHierarchyContextProvider>
            <ReportPeriodFlatContextProvider>
              <EventContextProvider>
                <ContactRelationshipTypeContextProvider>
                  <DisabilityTypeContextProvider>
                    <HispanicOrLatinoTypeContextProvider>
                      <EthnicityTypeContextProvider>
                        <NarrativeTypeContextProvider>
                          <IneligibleReasonContextProvider>
                            <SocialMediaTypeContextProvider>
                              <FunFactsTypeContextProvider>
                                <FrequentFlyerTypeContextProvider>
                                  <SupportStaffTypeContextProvider>
                                    <TestingBodyContextProvider>
                                      <DrugTestStatusContextProvider>
                                        <SponsorContextProvider>
                                          <CoachTypeContextProvider>
                                            <CompetitionGenderTypeContextProvider>
                                              <OrgGroupPropertiesContextProvider>
                                                {children}
                                              </OrgGroupPropertiesContextProvider>
                                            </CompetitionGenderTypeContextProvider>
                                          </CoachTypeContextProvider>
                                        </SponsorContextProvider>
                                      </DrugTestStatusContextProvider>
                                    </TestingBodyContextProvider>
                                  </SupportStaffTypeContextProvider>
                                </FrequentFlyerTypeContextProvider>
                              </FunFactsTypeContextProvider>
                            </SocialMediaTypeContextProvider>
                          </IneligibleReasonContextProvider>
                        </NarrativeTypeContextProvider>
                      </EthnicityTypeContextProvider>
                    </HispanicOrLatinoTypeContextProvider>
                  </DisabilityTypeContextProvider>
                </ContactRelationshipTypeContextProvider>
              </EventContextProvider>
            </ReportPeriodFlatContextProvider>
          </OrgRoleHierarchyContextProvider>
        </OrgUnitContextProvider>
      </LscContextProvider>
    </OrganizationContextProvider>
  );
};

export default AppStateContextProviders;