import { useState, useEffect } from 'react';
import { useLocation, useNavigate } from '../../../../../common/wrappers/ReactRouterDom';

import validate from './OtsSessionsDetailValidation';

import useOtsMeetSessionsData from '../../../../state/officialsTrackingSystemMeetSessions/UseOtsMeetSessionsData';

import useMeetSessionsData from '../../../../../common/state/meetSessions/UseMeetSessionsData';

import Constants from '../../../../../common/utils/Constants';
import { formatDate, formatDateTimeToTimeTwelveHourClock } from '../../../../../common/utils/DateFunctions';

const INITIAL_VIEW_STATE = {
  trySave: false,
  tryRedirect: false
};

const useOtsSessionsDetail = () => {
  const navigate = useNavigate();
  const { otsMeetSessionsState, putMeetSession } = useOtsMeetSessionsData();
  const location = useLocation();
  const [state, setState] = useState(INITIAL_VIEW_STATE);
  const { meetSessionsState, setMeetSessionsState, resetDetailViewState } = useMeetSessionsData();

  const saveFunction = (e) => {
    if (e?.preventDefault) {
      e.preventDefault();
    }
    putMeetSession(meetSessionsState.formState?.meetSessionId,
      { isOfficialsQualifyingSession: meetSessionsState.formState?.isOfficialsQualifyingSession }
    );
  }

  const tryValidateBeforeSave = async () => {
    const errors = await validate(meetSessionsState.formState) || {};
    setMeetSessionsState({ ...meetSessionsState, errorState: errors });
    if (Object.keys(errors).length === 0) {
      saveFunction();
      setState({ ...state, tryRedirect: true });
    }
  };

  const tryValidateBeforeRedirect = async () => {
    const errors = await validate(meetSessionsState.formState) || {};
    setMeetSessionsState({ ...meetSessionsState, errorState: errors });
    if (Object.keys(errors).length === 0) {
      setState({ ...state, tryRedirect: true });
    }
  };

  const onSaveButtonClicked = (e) => {
    if (e?.preventDefault) {
      e.preventDefault();
    }
    if (meetSessionsState.isDirty === false) {
      tryValidateBeforeRedirect();
    }
    else {
      tryValidateBeforeSave();
    }
  };

  const onBackButtonClicked = (e) => {
    if (e?.preventDefault) {
      e.preventDefault();
    }
    resetDetailViewState();
    navigate(meetSessionsState.backNavLink);
  };

  const onFormValueChangedSetMeetSessionsState = (compnentName, componentValue) => {
    if (meetSessionsState.formState[compnentName] !== componentValue) {
      const updatedFormState = { ...meetSessionsState.formState, [compnentName]: componentValue };
      setMeetSessionsState({ ...meetSessionsState, formState: updatedFormState, isDirty: true });
    }
  };

  const onValueTextPairChangedSetMeetSessionsState = (newValue, valuePropertyName, newValueLabel, labelPropertyName) => {
    if (meetSessionsState.formState[valuePropertyName] !== newValue
      || meetSessionsState.formState[labelPropertyName] !== newValueLabel) {
      const updatedFormState = {
        ...meetSessionsState.formState,
        [valuePropertyName]: newValue,
        [labelPropertyName]: newValueLabel
      };
      setMeetSessionsState({ ...meetSessionsState, formState: updatedFormState, isDirty: true });
    }
  };

  useEffect(() => {
    if (meetSessionsState?.meetSessionArray && Object.keys(meetSessionsState?.meetSessionArray).length > 0) {
      //Edit
      const meetSessionArrayCopy = JSON.parse(JSON.stringify(meetSessionsState?.meetSessionArray));
      if (meetSessionArrayCopy.length > 0 && location.state && location.state?.meetSessionId) {
        const selectedMeetSession = meetSessionArrayCopy?.find(x =>
          x.meetSessionId === location?.state?.meetSessionId);
        if (Object.keys(selectedMeetSession).length > 0) {
          setMeetSessionsState({
            ...meetSessionsState,
            formState: {
              ...meetSessionsState.formState,
              meetSessionId: selectedMeetSession.meetSessionId || Constants.DEFAULT_ID,
              sessionOrderId: selectedMeetSession.sessionOrderId || Constants.DEFAULT_ID,
              sessionTypeId: selectedMeetSession.sessionTypeId || Constants.DEFAULT_ID,
              sessionTypeName: selectedMeetSession?.sessionType?.typeName || '',
              sessionDate: selectedMeetSession.sessionDate ? formatDate(selectedMeetSession.sessionDate) : '',
              startTimeTwelveHourClock: selectedMeetSession.startTime ? formatDateTimeToTimeTwelveHourClock(selectedMeetSession.startTime) : '',
              warmUpTimeTwelveHourClock: selectedMeetSession.warmUpTime ? formatDateTimeToTimeTwelveHourClock(selectedMeetSession.warmUpTime) : '',
              isOfficialsQualifyingSession: selectedMeetSession.isOfficialsQualifyingSession ?? ''
            },
            isDirty: false
          });
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (otsMeetSessionsState.isSaving === false && state.tryRedirect === true) {
      resetDetailViewState();
      navigate(meetSessionsState.saveNavLink, { state: { tryGet: true } });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [otsMeetSessionsState.isSaving, state.tryRedirect]);

  return {
    meetSessionsState,
    setMeetSessionsState,
    otsMeetSessionsState,
    onSaveButtonClicked,
    onBackButtonClicked,
    onFormValueChangedSetMeetSessionsState,
    onValueTextPairChangedSetMeetSessionsState
  };
};

export default useOtsSessionsDetail;