import { Fragment } from 'react';

import useMemberCoursesWrite from '../UseMemberCoursesWrite';

import MemberCoursesWriteGrid from './MemberCoursesWriteGrid';

import Headings from '../../../../../common/components/headings/Headings';
import PopUpModal from '../../../../../common/components/dialogs/PopUpModal';
import ActionIntraPageButton from '../../../../../common/components/buttons/ActionIntraPageButton';

import Constants from '../../../../../common/utils/Constants';

const viewLocation = 'LSC';

const MemberCoursesWrite = () => {
  const {
    basePersonState,
    gridDataState,
    isGridLoading,
    onGoToMemberCoursesDetailView,
    onEditCourse
  } = useMemberCoursesWrite(viewLocation);

  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12">
          <Headings.H3>Member Courses</Headings.H3>
        </div>
      </div>
      <div className="row usas-extra-bottom-margin">
        <div className="col-xs-12 col-sm-6 col-md-4">
          <ActionIntraPageButton type="button" onClick={(e) => onGoToMemberCoursesDetailView(e, viewLocation)}>Add Course</ActionIntraPageButton>
        </div>
        <div className="col-xs-12 usas-extra-top-margin">
          <MemberCoursesWriteGrid
            gridData={gridDataState}
            isLoading={isGridLoading}
            onEditCourse={onEditCourse}
            viewLocation={viewLocation} />
        </div>
      </div>
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={basePersonState.isObjLoading || isGridLoading} />
    </Fragment>
  );
};

export default MemberCoursesWrite;