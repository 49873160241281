import { formatDate } from '../../../../../common/utils/DateFunctions';

import HideIcon from '../../../../../common/components/icons/HideIcon';
import ShowIcon from '../../../../../common/components/icons/ShowIcon';

import global from '../../../../../common/components/GlobalStyle.module.css';
import style from './LargeGrid.module.css';

const RuleNotes = ({ ruleNotes }) => {
  return (
    <>
      <em><strong>Additional clarification of requirement:</strong></em>
      <ul className={style.BulletList}>
        {ruleNotes.map((note, i) => <ul key={i} className={style.Bullet}>{note.ruleNote}</ul>)}
      </ul>
    </>
  );
};

const DetailTable = ({ certificationRule }) => {
  return (
  <>
    <table className={global.DetailTable}>
      <tbody>
      {certificationRule.reviewerLastName && (
        <tr>
          <td><strong>Rule Value:</strong></td>
          <td>{certificationRule.ruleValue}</td>
        </tr>
      )}
      {certificationRule.reviewerLastName && (
        <tr>
          <td><strong>Completion Date:</strong></td>
          <td>
            {certificationRule.completionDate && certificationRule.completionDate !== ''
              && formatDate(certificationRule.completionDate)}
          </td>
        </tr>
      )}
    {Array.isArray(certificationRule.ruleNotes) && certificationRule.ruleNotes.length > 0 && (
        <tr>
          <td colSpan={2}>
            <RuleNotes ruleNotes={certificationRule.ruleNotes} />
          </td>
        </tr>
    )}
      </tbody>
    </table>
    
  </>
  );
};

const TableRow = ({ certificationRule, expandedId, onExpandClicked }) => {
  return (
    <>
      <tr>
        <td>{certificationRule.requirementType}</td>
        <td>{certificationRule.ruleDescription}</td>
        <td>
          {(certificationRule.reviewerLastName 
            || (Array.isArray(certificationRule.ruleNotes) && certificationRule.ruleNotes.length > 0)) 
          && (
          <button
            className={global.IconButtonMargin}
            type="button"
            onClick={(e) => onExpandClicked(e, certificationRule.certificationRuleId)}>
            {expandedId === certificationRule.certificationRuleId ? <HideIcon /> : <ShowIcon />}
          </button>)}
        </td>
      </tr>
      {expandedId === certificationRule.certificationRuleId && (
        <tr className={global.Expanded}>
          <td colSpan="3">
            <DetailTable certificationRule={certificationRule} />
          </td>
        </tr>
      )}
    </>
  );
};

const GridLarge = ({ gridData, expandedId, onExpandClicked }) => {
  return (
    <>
      <table className={[global.UsasTable, 'visible-md visible-lg'].join(' ')}>
        <thead>
          <tr>
            <th className={style.RequirementTypeColumn}>Requirement Type</th>
            <th>Description</th>
            <th className={style.ExpandColumn}></th>
          </tr>
        </thead>
        <tbody>
          {Array.isArray(gridData) && gridData.length > 0 
            && gridData.map((certificationRule, i) => <TableRow key={i} certificationRule={certificationRule} expandedId={expandedId} onExpandClicked={onExpandClicked} />)}
          {(!Array.isArray(gridData) || !gridData?.length) && (
            <tr>
              <td colSpan="3">No data for grid</td>
            </tr>
          )}
        </tbody>
      </table>
    </>
  );
};

export default GridLarge;