
export const localValidate = (formState) => {
  let errors = {};

  if (formState.isOfficialsQualifyingSession !== true && formState.isOfficialsQualifyingSession !== false) {
    errors.isOfficialsQualifyingSession = 'Must select yes or no for this field';
  }

  return errors;
};

const OtsSessionsDetailValidation = async (formState) => {
  let errors = localValidate(formState);

  return errors ? errors : {};
};

export default OtsSessionsDetailValidation;