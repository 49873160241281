import { BASIC_INITIAL_STATE } from '../../../common/utils/HttpHelper';

import getMeetSessionData from './GetMeetSessionData';
import putMeetSessionData from './PutMeetSessionData';

const INITIAL_STATE = {
  ...BASIC_INITIAL_STATE,
  options: []
};

const OtsMeetSessionsData = {
  INITIAL_STATE, getMeetSessionData, putMeetSessionData
}

export default OtsMeetSessionsData;